import React from "react";
import { useController } from "react-hook-form";
import { useDispatch } from "react-redux";
import { updateDatas } from "../slice/userSelectionSlice";

const SpecialRequest = () => {
  const { field } = useController({
    name: `special_request`,
  });
  const dispatch = useDispatch();
  return (
    <div className="bg-white p-5 rounded-md">
      <div className="flex justify-between items-center mb-5">
        <h3 className="gold-color text-fs-21 leading-lh-27 merriweather font-bold">
          Demande particulière
        </h3>
      </div>
      <textarea
        onChange={(e) => {
          field.onChange(e);
          dispatch(
            updateDatas({ button: { type: "UPDATE_MODE" }, mode: "edit" })
          );
        }} // send value to hook form
        onBlur={field.onBlur} // notify when input is touched/blur
        value={field.value} // input value
        name={field.name} // send down the input name
        ref={field.ref} // send input ref, so we can focus on input when error appear
        className="h-110 rounded-md border border-gray-200 w-full pt-4 pb-5 px-5 text-fs-14 main-color outline-0 leading-lh-25"
      ></textarea>
    </div>
  );
};

export default SpecialRequest;
