import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormContext, useController, useWatch } from "react-hook-form";
import { getRooms } from "../actions";
import {
  getProductsFiltered,
  getFiltersOptions,
  filterHotelData,
} from "../selectors/products";
import { validateRepartion } from "../validation";
import { getDatas } from "../selectors/userSelection";
import { getDestination, getIsland } from "../selectors/destination";
import { format } from "date-fns";
import ErreurModal from "../components/ErreurModal";
import HotelTab from "../components/HotelTab";
import Select from "../components/Select";
import searchAccent from "../img/icons/search-accent.svg";
import CancelButton from "../components/CancelButton";
import warning from "../img/icons/warning.svg";
import ValidateButton from "../components/ValidateButton";
import { ErrorMessage } from "@hookform/error-message";

const HotelPopMode = ({ block, name }) => {
  const dispatch = useDispatch();
  const {
    getValues,
    setValue,
    register,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  const { num, dest, island, default_values = {}, index_parent, flag } = block;
  const is_multi_island = island === undefined ? false : true;
  const values = getValues();
  const label_hotel = `code_hotel_${index_parent}_${num}`;
  const products = useSelector(
    getProductsFiltered(dest, island, values["children"])
  );
  const {
    uniqueCityArray,
    uniqueCategoriesArray,
    uniqueThemesArray,
    uniqueRegionArray,
  } = getFiltersOptions(products);

  const category_filter = useWatch({
    name: `${index_parent}.${num}.category_filter`,
  });
  const theme_filter = useWatch({
    name: `${index_parent}.${num}.theme_filter`,
  });
  const cities_filter = useWatch({
    name: `${index_parent}.${num}.cities_filter`,
  });
  const regions_filter = useWatch({
    name: `${index_parent}.${num}.regions_filter`,
  });

  const search_filter = useWatch({
    name: `${index_parent}.${num}.search`,
  });

  const filteredData = filterHotelData(
    products,
    category_filter,
    theme_filter,
    cities_filter,
    search_filter,
    regions_filter
  );

  let code = "";
  if ("code" in default_values && default_values["code"].length > 0) {
    code = default_values["code"];
  } else if (values.hasOwnProperty(label_hotel)) {
    code = values[label_hotel];
  }

  let validateStrings = [label_hotel];
  const numberOfRooms =
    `${index_parent}` in values &&
    num in values[index_parent] &&
    `${code}` in values[index_parent][num] &&
    "nbr_rooms" in values[index_parent][num][code]
      ? values[index_parent][num][code]["nbr_rooms"]
      : 1;

  const roomArray = Array.from({ length: numberOfRooms }, (_, index) => index);
  (roomArray || []).forEach((_, key) => {
    validateStrings.push(`${index_parent}.${num}.${code}.room.${key}.code`);
  });

  const { pensions_data } = code in products ? products[code] : {};
  const roomsData = useSelector((state) => state.roomsReducer.rooms);
  const { rooms } = code in roomsData ? roomsData[code] : {};
  const { field } = useController({
    name: label_hotel,
    defaultValue: code,
    rules: {
      validate: (value, formValues) =>
        validateRepartion(value, formValues, num, index_parent),
    },
  });

  const {
    staydates: { startDate, endDate },
    next_dest,
    current_dest,
    ...datas
  } = useSelector(getDatas);
  const { title } = useSelector(getIsland(dest)(island));
  const { label_destination, combinaison_destinations } = useSelector(
    getDestination(datas["dest"])
  );
  const current_location =
    title !== undefined
      ? title
      : current_dest === datas["dest"]
      ? label_destination
      : combinaison_destinations[current_dest];
  const start_date = format(new Date(startDate), "dd/MM/yyyy");
  const end_date = format(new Date(endDate), "dd/MM/yyyy");

  const [openTab, setOpenTabS] = useState(code);
  const [openTabV, setOpenTabV] = useState("hotel");

  const setOpenTab = (tab) => {
    setOpenTabS(tab);
    setOpenTabV("hotel");
  };
  useEffect(() => {
    if (openTab.length !== 0) {
      setValue(label_hotel, openTab);
      clearErrors();
      dispatch(getRooms({ ...getValues(), code_hotel: openTab }));
    }
  }, [setValue, openTab, dispatch, getValues, clearErrors, label_hotel]);

  return (
    <>
      {Object.keys(products).length > 0 && (
        <div className="modal">
          <input type="hidden" {...field} />
          <div className="modal-content max-w-1172">
            <div className="flex justify-between pb-5">
              <h3 className="main-color text-fs-21 leading-lh-37 merriweather font-bold ">
                {`Votre voyage du ${start_date} au ${end_date} – ${label_destination}`}
                {next_dest !== undefined && next_dest !== "none" && (
                  <> {` &  ${combinaison_destinations[next_dest]}`}</>
                )}
              </h3>
            </div>

            <div className="flex justify-between items-center border-b border-b-gray-200 pb-value-30">
              <h3 className="gold-color text-fs-21 leading-lh-37 merriweather font-bold ">
                {name}{" "}
                {current_location !== undefined && " - " + current_location}
              </h3>
              <div className="flex [&>select]:mr-2.5 tablet:[&>select]:mb-2.5  [&>select:last-child]:ml-0 tablet:flex-wrap">
                {uniqueCategoriesArray.length > 2 && (
                  <Select
                    id={`${index_parent}.${num}.category_filter`}
                    default_value={"all"}
                    options={uniqueCategoriesArray}
                    ClassNames={
                      "custom-select-arrow-2 !w-value-170 border-1-5 border-accent font-semibold"
                    }
                  />
                )}
                {is_multi_island === false && uniqueCityArray.length > 2 && (
                  <Select
                    id={`${index_parent}.${num}.cities_filter`}
                    default_value={"all"}
                    options={uniqueCityArray}
                    ClassNames={
                      "custom-select-arrow-2 !w-value-170 border-1-5 border-accent font-semibold"
                    }
                  />
                )}
                {uniqueRegionArray.length > 2 && (
                  <Select
                    id={`${index_parent}.${num}.regions_filter`}
                    default_value={"all"}
                    options={uniqueRegionArray}
                    ClassNames={
                      "custom-select-arrow-2 !w-value-170 border-1-5 border-accent font-semibold"
                    }
                  />
                )}
                {uniqueThemesArray.length > 2 && (
                  <Select
                    id={`${index_parent}.${num}.theme_filter`}
                    default_value={"all"}
                    options={uniqueThemesArray}
                    ClassNames={
                      "custom-select-arrow-2 !w-value-170 border-1-5 border-accent font-semibold"
                    }
                  />
                )}
              </div>
            </div>

            <div className="pt-value-30">
              <div className="flex tablet:flex-col">
                <div className="desktop:w-260 tablet:mb-value-30">
                  <span className="block main-color font-semibold mb-5">
                    Choisissez votre {name}
                  </span>
                  <div className="flex flex-col rounded-md border border-gray-200">
                    <div className="py-2.5 px-4">
                      <div className="relative flex items-center">
                        <input
                          {...register(`${index_parent}.${num}.search`)}
                          className="h-value-40 outline-0 w-full px-4 rounded-34 bg-grey-pagination"
                          type="text"
                          placeholder="Recherche…"
                        />
                        <img
                          className="max-w-13 absolute right-value-24"
                          src={searchAccent}
                          alt=""
                        />
                      </div>
                    </div>

                    <ul className="[&>li]:cursor-pointer main-color list-hotels max-h-800 overflow-auto">
                      {filteredData.length === 0 ? (
                        <span className="flex pl-5 h-value-64">
                          Aucun hôtel ne correspond à vos filtres.
                        </span>
                      ) : (
                        filteredData.map((key) => {
                          return (
                            <li
                              key={key}
                              className={`relative flex items-center [&.active]:bg-custom-grey [&:hover]:bg-custom-grey [&.active]:before:absolute [&.active]:before:h-13 [&.active]:before:w-18.5 [&.active]:before:right-5 ${
                                openTab === key ? "active" : ""
                              }`}
                            >
                              <button
                                className="w-value-203 pl-5 h-value-64 text-left overflow-hidden text-ellipsis inline-block whitespace-nowrap"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setOpenTab(key);
                                }}
                                disabled={
                                  !(
                                    flag === undefined ||
                                    ((flag === "SHOW_ONLY_CODE" ||
                                      flag === "ADD_ROOM_CATEGORY") &&
                                      key === default_values["code"])
                                  )
                                }
                              >
                                {products[key]["label"]}
                              </button>
                            </li>
                          );
                        })
                      )}
                    </ul>
                  </div>
                </div>
                {openTab in products && (
                  <HotelTab
                    key={products[openTab]["code"]}
                    block={block}
                    product={products[openTab]}
                    code_hotel={products[openTab]["code"]}
                    openTab={openTabV}
                    setOpenTab={setOpenTabV}
                  />
                )}
              </div>
            </div>
            <div className="btn-select-hotel flex flex-col mt-value-30">
              {Object.keys(errors).length > 0 && (
                <div className="flex text-fs-14 px-5 py-3.5 bg-gold rounded-md items-center mb-value-30 bigdesktop:w-1132 bigdesktop:-ml-235">
                  <img className="self-start mr-5" src={warning} alt="" />
                  <div>
                    {(validateStrings || []).map((name, key) => {
                      return (
                        <ErrorMessage
                          key={key}
                          errors={errors}
                          name={name}
                          render={({ message }) => {
                            return (
                              // Check if message is not empty before rendering
                              message && (
                                <span className="text-white block font-semibold">
                                  {message}
                                </span>
                              )
                            );
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            <div className="w-full flex items-end justify-center">
              <div className="flex flex-col mt-5">
                {code.length > 0 && openTabV === "hotel" && (
                  <ValidateButton
                    classes={
                      "p-16-35 leading-lh-15 block mx-auto main-bg-color text-white br-26 text-fs-14 w-value-226 disabled:bg-disabled-button"
                    }
                    button={{
                      label: "Valider",
                      type: "SELECT_HOTELS",
                    }}
                    block={block}
                    validateStrings={validateStrings}
                    extra_data={{
                      pension: { ...pensions_data },
                      rooms_fares: { ...rooms },
                    }}
                  />
                )}
                <CancelButton block={block} index={index_parent} />
              </div>
            </div>
          </div>
        </div>
      )}
      {Object.keys(products).length === 0 && (
        <ErreurModal
          message={`Erreur de la recherche des produits de la destination ${dest}`}
        />
      )}
    </>
  );
};

export default HotelPopMode;
