import { configureStore } from "@reduxjs/toolkit";
import { userSelectionReducer } from "../slice/userSelectionSlice";
import { destinationReducer } from "../slice/destinationSlice";
import { flightReducer } from "../slice/flightSlice";
import { productsReducer } from "../slice/productsSlice";
import { roomsReducer } from "../slice/roomsSlice";
import { assuranceReducer } from "../slice/assuranceSlice";
import { realTimeReducer } from "../slice/realTimeSlice";
import { combineReducers } from "redux";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas";

const sagaMiddleware = createSagaMiddleware();

const reducer = combineReducers({
  userSelectionReducer,
  destinationReducer,
  flightReducer,
  productsReducer,
  roomsReducer,
  assuranceReducer,
  realTimeReducer,
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(sagaMiddleware, logger),
  devTools: true,
});

sagaMiddleware.run(rootSaga);
