import { getFirsttKey, getLastKey } from "../helper";
import {
  getIslandIndex,
  getFormDatas,
  getDatas,
  getUserSelectionSate,
} from "../selectors/userSelection";
import { createSelector } from "reselect";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

const getDestinationState = (state) => {
  return state.destinationReducer.destination;
};
const isSuccess = (dest) => (state) => {
  const success_obj = state.destinationReducer.isSuccess;
  if (dest in success_obj) return success_obj[dest];
  return false;
};

const isLoading = (dest) => (state) => {
  const loading_obj = state.destinationReducer.isLoading;
  if (dest !== undefined && dest !== "none" && dest in loading_obj)
    return loading_obj[dest];
  return false;
};

const DestCodeExist = (state, code) => {
  return state.destinationReducer.destination.hasOwnProperty(code);
};

const isError = (dest) => (state) => {
  const error_obj = state.destinationReducer.isError;
  if (dest in error_obj) return error_obj[dest];
  return false;
};

const getDestination = (dest) => {
  return createSelector([getDestinationState], (destination_state) => {
    if (dest === undefined || dest === "none") return {};
    return dest in destination_state ? destination_state[dest] : {};
  });
};

const getIsland = (dest) => (island) => {
  return createSelector([getDestination(dest)], (destination_state) => {
    const { island_contents } = destination_state;
    if (island_contents !== undefined && island in island_contents)
      return island_contents[island];
    return {};
  });
};

const getIslandsContent = (dest) => (state) => {
  const destination = getDestination(dest)(state);
  return destination["island_contents"];
};

const IsMultiIsland = (dest) => (state) => {
  const all_destinations = getAllDestinations(state);
  if (dest in all_destinations) {
    const datas = getDestination(dest)(state);
    return datas["bl_multiisland"];
  }
  return 0;
};

const islandCombinations = (dest) => (state) => {
  const all_destinations = getAllDestinations(state);
  if (dest in all_destinations) {
    const datas = getDestination(dest)(state);
    return datas["island_combinations"];
  }
  return [];
};

const possibleInteriorFlights = (is_multi_island) => (dest) => {
  return createSelector(
    [getFirstDestination, getUserSelectionSate],
    ({ island_contents }, { data: { dates }, form_data: { islands } }) => {
      let possible = true;
      let messages = [];
      if (is_multi_island === true && dest === "POL") {
        // On a sélectionné qu'une seule île
        if (Object.keys(islands).length > 1) {
          const forbidden_flights = {
            "BOB/HUH": 6, // samedi
            "BOB/TIH": 4, // jeudi
            "FAV/PPT": 0, // dimanche
            "RFP/HUH": 3, // mercredi
            "TIH/RGI": 1, //lundi
          };
          for (const key in islands) {
            const island = islands[key];
            if (parseInt(key) + 1 in islands) {
              const key_combi = island + "/" + islands[parseInt(key) + 1];
              if (key_combi in forbidden_flights) {
                if (island in dates) {
                  let possibility = true;
                  dates[island].forEach((date) => {
                    const { end_day } = date;
                    const end_day_formatted = new Date(end_day);
                    const end_day_full = format(
                      new Date(end_day),
                      "eeee dd MMM yyyy",
                      {
                        locale: fr,
                      }
                    );
                    const dayOfWeek = end_day_formatted.getDay();
                    if (forbidden_flights[key_combi] === dayOfWeek) {
                      possibility = false;
                      const first_island_title =
                        island in island_contents
                          ? island_contents[island]["title"]
                          : island;

                      const second_island_title =
                        islands[parseInt(key) + 1] in island_contents
                          ? island_contents[islands[parseInt(key) + 1]]["title"]
                          : islands[parseInt(key) + 1];

                      let message =
                        "Le trajet " +
                        first_island_title +
                        " - " +
                        second_island_title +
                        " n'est pas disponible le " +
                        end_day_full +
                        ", veuillez modifier le nombre de nuits sur " +
                        first_island_title;
                      messages.push(message);
                    }
                  });
                  possible = possibility;
                }
              }
            }
          }
        }
      }
      return { messages, possible };
    }
  );
};

const getIslands = (dest) => {
  return createSelector(
    [getIslandIndex, getDestination(dest), getFormDatas, getDatas],
    (index, { island_contents, ...datas }, form_datas, userDatas) => {
      let island_selected = "";
      let unique_islands = [];
      const {
        staydates: { startDate, endDate },
      } = userDatas;

      const start_day_num = new Date(startDate).getDay();
      const end_day_num = new Date(endDate).getDay();
      let islands = datas["island_combinations"];
      (islands || []).forEach((island) => {
        let array_island = island.split(",");
        // Si le dernier jour de voyage est un lundi ou un mercredi (jour 1 et 3), obliger Papeete en dernière île.
        if ((end_day_num === 1 || end_day_num === 3) && dest === "POL") {
          const last_island = array_island[array_island.length - 1];
          if (last_island !== "PPT") {
            return;
          }
        }
        if (index === 0) {
          // Si le jour de début de voyage est un mardi : ne pas proposer MOZ en première île.
          if (
            !unique_islands.includes(array_island[index].trim()) &&
            !(
              array_island[index].trim() === "MOZ" &&
              start_day_num === 2 &&
              dest === "POL"
            )
          )
            unique_islands.push(array_island[index].trim());
        } else if (index > 0) {
          island_selected = form_datas["islands"];
          let found = true;
          const indexArray = Array.from({ length: index }, (_, i) => i);
          for (var i = 0; i < indexArray.length; i++) {
            if (array_island[i] !== island_selected[i]) {
              found = false;
              break;
            }
          }
          if (found === true && array_island[index] !== undefined) {
            if (!unique_islands.includes(array_island[index].trim()))
              unique_islands.push(array_island[index].trim());
          }
        }
      });
      unique_islands.sort((a, b) => {
        if (b in island_contents && a in island_contents) {
          return island_contents[a].weight - island_contents[b].weight;
        } else {
          return false;
        }
      });
      return unique_islands;
    }
  );
};

const isPossibleCombination = (dest) => (state) => {
  const island_selected = getFormDatas(state)["islands"];
  const { island_combinations } = getDestination(dest)(state);
  let result = [];
  if (Array.isArray(island_combinations)) {
    result = island_combinations.filter((island) => {
      let same = true;
      const array_island = island.split(",");
      if (array_island.length === Object.keys(island_selected).length) {
        const indexArray = Array.from(
          { length: array_island.length },
          (_, i) => i
        );
        for (var i = 0; i < indexArray.length; i++) {
          let island_trim = array_island[i].trim();
          if (island_selected[i] !== island_trim) {
            same = false;
            break;
          }
        }
        return same;
      } else {
        return false;
      }
    });
  }

  return result.length > 0 ? true : false;
};

const getAllDestinations = (state) => state.destinationReducer.destination;

const getDestinationError = (state) => state.destinationReducer.error;

const getFirstDestinationCode = (state) => {
  const destinations = getAllDestinations(state);
  return (typeof destinations === "object") &
    (Object.keys(destinations).length !== 0)
    ? getFirsttKey(destinations)
    : "";
};

const getFirstDestination = createSelector(
  [getDestinationState],
  (destination_state) => {
    // Compute the result of selectB using the result of selectA
    const code =
      (typeof destination_state === "object") &
      (Object.keys(destination_state).length !== 0)
        ? getFirsttKey(destination_state)
        : "";
    const destination =
      code !== "" && code in destination_state ? destination_state[code] : {};
    return destination;
  }
);

const getDepartureCities = createSelector(
  [getFirstDestination],
  (first_destination) => {
    const { departure_cities } = first_destination;
    // Convert the object to an array of [key, value] pairs
    const entries = Object.entries(departure_cities);

    // Sort the array by the values (second element of each pair)
    entries.sort((a, b) => {
      if (a[1] === null) return 1;
      if (b[1] === null) return -1;
      return a[1].localeCompare(b[1]);
    });

    // Convert the sorted array back to an object
    const sorted_departure_cities = Object.fromEntries(entries);
    let departure_cities_options = [];
    Object.keys(sorted_departure_cities).forEach((key) => {
      departure_cities_options.push({
        value: key,
        label: sorted_departure_cities[key],
      });
    });
    return departure_cities_options;
  }
);

const getDepartureCityLabel = (code) => (state) => {
  const { departure_cities } = getFirstDestination(state);
  return typeof departure_cities === "object" && code in departure_cities
    ? departure_cities[code]
    : "";
};

const getLastSelectedIsland = (state) => {
  const { islands } = getFormDatas(state);
  return Object.keys(islands).length > 0 ? islands[getLastKey(islands)] : "";
};

export {
  getDestinationError,
  getFirstDestination,
  getDestination,
  IsMultiIsland,
  islandCombinations,
  getIslands,
  getIsland,
  getIslandsContent,
  getAllDestinations,
  getDepartureCities,
  isSuccess,
  isLoading,
  getDepartureCityLabel,
  isError,
  isPossibleCombination,
  getFirstDestinationCode,
  DestCodeExist,
  possibleInteriorFlights,
  getLastSelectedIsland,
};
