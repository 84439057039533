import { put } from "redux-saga/effects";
import { fetchWithTimeout } from "../helper";

function* fetchRooms(action) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  let { api_key, ...data } = action.payload;
  const headers = new Headers();
  if (api_key.length > 0) headers.append("api-key", api_key);
  const code = data["code_hotel"];
  let request = `${API_ENDPOINT}/api/v1/rooms/` + code + "?";
  request += "adultes=" + data[`adults`];
  request += "&children=" + data[`children`];
  request += "&departure=" + data["staydates"]["startDate"];
  request += "&return=" + data["staydates"]["endDate"];

  try {
    const response = yield fetchWithTimeout(request, { headers });
    if (response.status === 200) {
      const json = yield response.json();
      yield put({ type: "rooms/putRooms", json, code });
    } else {
      throw response;
    }
  } catch (e) {
    yield put({ type: "rooms/errorApiRooms", message: e.message, code });
  }
}

export { fetchRooms };
