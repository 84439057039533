import { format, eachDayOfInterval } from "date-fns";
import { fr } from "date-fns/locale";
import { getVisitedDesti, getDatas } from "../selectors/userSelection";
import {
  getAllDestinations,
  getDestination,
  getIsland,
} from "../selectors/destination";

export const getExcursions = (state) => state.excursionReducer.excursions;
export const isSuccess = (state) => state.excursionReducer.isSuccess;
export const isLoading = (state) => state.excursionReducer.isLoading;
export const isError = (state) => state.excursionReducer.isError;

export const getExcursionsDatas = (state) => {
  let excursions_datas = {};
  let excursions_datas_by_desti = {};
  const { dates, is_multi_island } = getDatas(state);
  const dests = getVisitedDesti(state);
  const destinations_datas = getAllDestinations(state);
  const dates_formatted = formatExcursionDates(dates);
  let has_data = false;
  if (is_multi_island !== "1") {
    dests.forEach((dest) => {
      excursions_datas_by_desti[dest] = {};
      excursions_datas_by_desti[dest]["data"] = {};
      excursions_datas_by_desti[dest]["dates"] = dates[dest];
      excursions_datas_by_desti[dest]["label"] =
        getDestination(dest)(state)["label_destination"];
      const excursions_contents =
        destinations_datas[dest]["excursions_contents"];
      Object.keys(excursions_contents).forEach((code) => {
        let excursion = excursions_contents[code];
        const validity = excursion.validity;
        let validity_options = [];
        if (validity.length === 0) {
          excursion = { ...excursion, validity_options };
          excursions_datas[code] = excursion;
          excursions_datas_by_desti[dest]["data"][code] = excursion;
          has_data = true;
        } else {
          const dates_formatted_dest = dates_formatted[dest];
          validity_options = getValidityOptions(dates_formatted_dest, validity);
          if (validity_options.length > 1) {
            excursion = { ...excursion, validity_options };
            excursions_datas[code] = excursion;
            excursions_datas_by_desti[dest]["data"][code] = excursion;
            has_data = true;
          }
        }
      });
    });
  } else {
    const dest = dests[0];
    Object.keys(dates).forEach((key) => {
      const island = key;
      excursions_datas_by_desti[island] = {};
      excursions_datas_by_desti[island]["data"] = {};
      excursions_datas_by_desti[island]["dates"] = dates[key];
      excursions_datas_by_desti[island]["label"] =
        getIsland(dest)(island)(state)["title"];
      const excursions_contents =
        destinations_datas[dest]["excursions_contents"];
      const filterExcursionsByIsland = Object.fromEntries(
        Object.entries(excursions_contents).filter(
          ([_, excursion]) => excursion.island === island
        )
      );
      Object.keys(filterExcursionsByIsland).forEach((code) => {
        let excursion = filterExcursionsByIsland[code];
        const validity = excursion.validity;
        let validity_options = [];
        if (validity.length === 0) {
          excursion = { ...excursion, validity_options };
          excursions_datas[code] = excursion;
          excursions_datas_by_desti[island]["data"][code] = excursion;
          has_data = true;
        } else {
          const dates_formatted_dest = dates_formatted[island];
          validity_options = getValidityOptions(dates_formatted_dest, validity);
          if (validity_options.length > 1) {
            excursion = { ...excursion, validity_options };
            excursions_datas[code] = excursion;
            excursions_datas_by_desti[island]["data"][code] = excursion;
            has_data = true;
          }
        }
      });
    });
  }
  return { excursions_datas_by_desti, has_data };
};

export const formatExcursionDates = (dates) => {
  let formatted_days_w = {};
  for (const dest in dates) {
    formatted_days_w[dest] = {};
    dates[dest].forEach((date) => {
      const { start_day, end_day } = date;
      const startDate = new Date(start_day);
      const endDate = new Date(end_day);
      const allDays = eachDayOfInterval({ start: startDate, end: endDate });
      const allDaysTruncted = allDays.slice(1, -1);
      (allDaysTruncted || []).forEach((date) => {
        let date_formatted = format(new Date(date), "yyyy-MM-dd");
        formatted_days_w[dest][date_formatted] = format(
          new Date(date),
          "eeee dd MMM yyyy",
          {
            locale: fr,
          }
        );
      });
    });
  }
  return formatted_days_w;
};

export const getValidityOptions = (date_formatted, validity) => {
  let validity_options = [
    {
      value: "all",
      label: "Choisir un jour",
    },
  ];
  Object.keys(date_formatted).forEach((date_non_formatted) => {
    let date = date_formatted[date_non_formatted];
    const firstCharUppercase = date.charAt(0).toUpperCase() + date.slice(1);
    let date_splitted = firstCharUppercase.split(" ");
    let day = date_splitted[0];
    if (validity.includes(day)) {
      validity_options.push({
        value: date_non_formatted,
        label: firstCharUppercase,
      });
    }
  });
  return validity_options;
};
