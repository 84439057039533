import React from "react";
import warning from "../img/icons/warning.svg";

const ErreurModal = ({ message }) => {
  return (
    <div className="modal">
      <div className="modal-content max-w-300 !p-0">
        <div className="flex text-fs-14 p-6 bg-gold text-center flex-col justify-center items-center rounded-md mb-value-30">
          <img src={warning} alt="" />
          <span className="text-white text-fs-14 block mt-5 font-semibold">
            {message}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ErreurModal;
