import React from "react";
import RadioButton from "./RadioButton";

const TransfertRow = ({ transfer, name }) => {
  let { transfer_code, image, title, subtitle, description } = transfer;

  return (
    <div className="border border-gray-200 rounded-md flex items-center justify-between py-2 px-4 mb-2.5 w-full">
      <div className="flex items-center">
        <span className="h-90 w-90 flex items-center justify-center">
          {image !== undefined && (
            <img
              className="h-auto w-auto object-cover"
              src={image["url"]}
              alt={image["alt"]}
            />
          )}
        </span>
        <div className="ml-5 flex flex-col">
          <div className="mb-2.5">
            <span className="font-bold">{title} </span>
            <span>{subtitle}</span>
          </div>
          <div dangerouslySetInnerHTML={{ __html: description }}></div>
        </div>
      </div>
      <RadioButton
        id={`${transfer_code}`}
        value={`${transfer_code}`}
        name={name}
      />
    </div>
  );
};

export default TransfertRow;
