import React from "react";
import ExcursionRecapMode from "../components/ExcursionRecapMode";
import { format } from "date-fns";

const ExcursionRecapModeGrouped = ({ user_datas, datas_content }) => {
  const { dates, label } = datas_content;
  return (
    <>
      <div className="flex justify-between">
        <h3 className="main-color text-fs-18 leading-lh-37 font-bold">
          <span className="font-bold merriweather">
            {label}
            {"  "}
          </span>
          {dates.map((date, key) => {
            return (
              <span key={key}>
                {key > 0 && <> & </>}
                {format(new Date(date["start_day"]), "dd/MM/yyyy")} -{" "}
                {format(new Date(date["end_day"]), "dd/MM/yyyy")}
              </span>
            );
          })}
        </h3>
      </div>
      {Object.keys(user_datas).map((key) => {
        return (
          <ExcursionRecapMode
            key={key}
            validity_day={user_datas[key]["validity_day"]}
            excursion={datas_content["data"][key]}
          />
        );
      })}
    </>
  );
};

export default ExcursionRecapModeGrouped;
