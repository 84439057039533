import React, { useRef } from "react";
import ReactPlayer from "react-player";
import plain from "../img/plain.jpg";

function YouTubeVideo({ videoURL }) {
  const videoRef = useRef(null);
  return (
    <div>
      {videoURL !== undefined ? (
        <div className="video-container">
          <ReactPlayer
            ref={videoRef}
            url={videoURL.url}
            className="w-full h-full video-wrapper"
            light={videoURL.image.length > 0 ? videoURL.image : plain}
            playing={true}
            controls={false}
            config={{
              youtube: {
                playerVars: { showinfo: 0, picture_in_picture: 0 },
              },
            }}
          />
        </div>
      ) : (
        <p>No videos available for this hotel.</p>
      )}
    </div>
  );
}

export default YouTubeVideo;
