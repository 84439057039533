import { createSlice } from "@reduxjs/toolkit";

const destinationSlice = createSlice({
  name: "destination",
  initialState: {
    destination: {},
    message: {},
    isLoading: {},
    isSuccess: {},
    isError: {},
  },
  reducers: {
    fetchDestination: (state, action) => {
      let { dest } = action.payload;
      state.isLoading[dest] = true;
    },
    putDestination: (state, action) => {
      const { json, dest } = action;
      state.destination[dest] = json.destination;
      state.isLoading[dest] = false;
      state.isSuccess[dest] = true;
    },
    errorApiDestination: (state, action) => {
      const { message, dest } = action;
      state.isLoading[dest] = false;
      state.isError[dest] = true;
      state.message[dest] = message;
    },
  },
});

export const destinationReducer = destinationSlice.reducer;

export const { fetchDestination, putDestination, errorApiDestination } =
  destinationSlice.actions;
