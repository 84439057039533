import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { transformComponent } from "./Components";
import {
  getComponents,
  getDatas,
  getFormDatas,
} from "../selectors/userSelection";
import {
  getFirstDestination,
  isPossibleCombination,
  possibleInteriorFlights,
} from "../selectors/destination";
import { useOnceCall } from "../hooks";
import logo from "../img/Logo-australlagons-white.svg";
import blue_logo from "../img/logo-australlagons-third.svg";
import Cookies from "js-cookie";
import chevron_left from "../img/icons/chevron-left.svg";
import { getDest } from "../actions";
/* 
import phone from "../img/icons/phone.svg";
import user from "../img/icons/user.svg"; */
function MasterForm() {
  const dispatch = useDispatch();
  const methods = useForm({
    defaultValues: {},
    resetOptions: {},
  });
  const onSubmit = (data) => console.log(data);
  const components = useSelector(getComponents);
  const data = useSelector(getDatas);
  const { available_days } = useSelector(getFormDatas);
  const { next_dest, dest } = data;
  const is_possible_combination = useSelector(isPossibleCombination(dest));

  const {
    image,
    label_destination,
    combinaison_destinations,
    node_path,
    bl_multiisland,
  } = useSelector(getFirstDestination);
  const { possible } = useSelector(
    possibleInteriorFlights(bl_multiisland === "1")(dest)
  );
  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.reload();
    }, 8 * 60 * 60 * 1000); // Refresh after 8 hours
    return () => clearTimeout(timeout);
  }, []);

  useOnceCall(() => {
    let api_key = "";
    const api_key_cookie = Cookies.get("api-key");
    if (api_key_cookie !== undefined) api_key = api_key_cookie;
    methods.setValue("api_key", api_key);
    let dest = new URL(document.location).searchParams.get("desti");
    if (dest) dest = dest.toUpperCase();
    methods.setValue("dest", dest);
    if (dest) {
      dispatch(getDest({ dest, api_key }));
    }
    const default_code_product = new URL(document.location).searchParams.get(
      "code"
    );
    if (default_code_product !== null)
      methods.setValue("default_code_product", default_code_product);
  });

  const validate = () => {
    let valid = true;
    if (available_days > 0) {
      valid = false;
    } else if (bl_multiisland === "1") {
      if (is_possible_combination === false) valid = false;
      if (dest === "POL" && possible === false) {
        valid = false;
      }
    }
    return valid || "Votre séjour est incomplet.";
  };

  return (
    <div id="wrapper" className="mx-auto max-w-1920">
      <div className="flex h-full w-full items-center justify-center left-0 top-0 fixed z-1111 bg-white tabletdesktop:hidden">
        <div className="px-5 pb-5 flex text-center flex-col main-color">
          <a
            className="absolute left-0 right-0 mx-auto top-5 block"
            href={`${process.env.REACT_APP_API_ENDPOINT}`}
          >
            <img className="max-w-[140px] mx-auto" src={blue_logo} alt="logo" />
          </a>
          <h2 className="merriweather font-bold text-fs-21 leading-lh-[70px] mb-5">
            Le formulaire de voyage sur mesure n’est pas disponible sur mobile
            et tablette.
          </h2>
          <span>
            Nous vous invitons à utiliser un écran de plus grande taille pour
            découvrir notre formulaire de voyage sur mesure.
          </span>
          <a
            className="p-16-35 bg-accent text-white br-26 inline-block font-bold mt-value-30"
            href={`${process.env.REACT_APP_API_ENDPOINT}`}
          >
            Retourner à l'accueil
          </a>
        </div>
      </div>
      <div id="" className="relative heroslideshow flex items-center">
        <div className="mt-value-40 mobiletablet:h-value-75 w-full flex nav-down absolute top-0 transform duration-500 z-10">
          <nav className="w-full flex justify-between items-end desktop:px-value-34 mobiletablet:px-4">
            <div className="flex items-end">
              <a
                id="logo"
                className="block desktop:w-value-215 mobiletablet:w-138 relative logo desktop:h-67 mobiletablet:h-42 mb-value-11"
                href={`${process.env.REACT_APP_API_ENDPOINT}`}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="logo-white mobiletablet:transition mobiletablet:duration-300 w-full"
                  src={logo}
                  alt="logo"
                />
              </a>
            </div>
            <div className="flex items-end pb-4">
              {/*      <ul className="right-items flex items-center desktop:ml-value-50 desktop:[&>li]:ml-value-40 mobiletablet:[&>li]:mr-value-25 [&>li:last-child]:mr-0">
                <li>
                  <a href="/">
                    <span className="relative block menu-item-icon desktop:w-value-27 desktop:h-value-27 mobiletablet:h-5 mobiletablet:w-5">
                      <img className="white-icon" src={phone} alt="" />
                    </span>
                  </a>
                </li>
                <li>
                  <a href="/" className="flex items-end">
                    <span className="relative block menu-item-icon desktop:w-value-26 desktop:h-value-26 mobiletablet:h-5 mobiletablet:w-5">
                      <img className="white-icon" src={user} alt="" />
                    </span>
                    <span className="ml-2.5 font-bold mobiletablet:hidden text-white">
                      Espace Pro
                    </span>
                  </a>
                </li>
              </ul> */}
            </div>
          </nav>
        </div>
        <div className="caption top-0 bottom-0 flex items-center flex-col justify-center absolute text-white tabletdesktop:left-0 tabletdesktop:right-0 tabletdesktop:mx-auto text-center z-20 max-w-720">
          {node_path !== undefined && (
            <a
              className="flex items-center mb-value-30"
              href={`${process.env.REACT_APP_API_ENDPOINT}/${node_path}`}
              target="_blank"
              rel="noreferrer"
            >
              <img src={chevron_left} alt="chevron_left" />
              <span className="text-fs-14 leading-lh-17 ml-2.5 font-bold">
                Retourner à la page précédente
              </span>
            </a>
          )}
          <h1 className="merriweather relative mobile:text-fs-34 tabletdesktop:text-fs-45 tabletdesktop:leading-lh-57 mobile:leading-lh-47">
            Créez votre voyage sur mesure <br />
            {label_destination}
            {next_dest !== undefined && next_dest !== "none" && (
              <> {` &  ${combinaison_destinations[next_dest]}`}</>
            )}
          </h1>
        </div>
        <div className="single-img-wrapper relative w-full h-full">
          {typeof image === "string" && image.length > 0 && (
            <img
              src={image}
              className="block w-full h-full object-cover"
              alt="..."
            />
          )}
        </div>
      </div>
      <div className="bg-creme">
        <div className="max-w-1172 mx-auto -mt-133  relative z-10">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="flex flex-col [&>div]:mb-5 [&>div:last-child]:mb-380 tablet:px-value-24 smalldesktop:px-value-24">
                {/* components here */}
                {components.map((block, index) => {
                  return transformComponent(block, index);
                })}
                <input
                  type="hidden"
                  {...methods.register("dest", {
                    validate: validate,
                  })}
                />
                <input type="hidden" {...methods.register("api_key")} />
                <input
                  type="hidden"
                  {...methods.register("default_code_product")}
                />
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
}
export default MasterForm;
