import { createSlice } from "@reduxjs/toolkit";

const productsSlice = createSlice({
  name: "products",
  initialState: {
    products: {},
    isLoading: {},
    isSuccess: {},
    isError: {},
  },
  reducers: {
    getProducts: (state, action) => {
      const desti = action.payload.products_desti;
      state.isLoading[desti] = true;
    },
    putProducts: (state, action) => {
      const desti = action.desti;
      state.products[desti] = action.json;
      state.isLoading[desti] = false;
      state.isSuccess[desti] = true;
    },
    errorApiProducts: (state, action) => {
      const desti = action.desti;
      state.isLoading[desti] = false;
      state.isError[desti] = true;
    },
    resetProducts: (state) => {
      state.products = {};
      state.isLoading = {};
      state.isSuccess = {};
      state.isError = {};
    },
  },
});

export const productsReducer = productsSlice.reducer;

export const { getProducts, putProducts, errorApiProducts, resetProducts } =
  productsSlice.actions;
