import { React, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormContext } from "react-hook-form";
import { isSuccess, isLoading, isError } from "../selectors/flights";
import { getFlights } from "../slice/flightSlice";
import { getDatas } from "../selectors/userSelection";
import Loading from "../components/LoadingFlight";
import FlightStepOne from "./FlightStepOne";
import FlightStepTwo from "./FlightStepTwo";
import warning from "../img/icons/warning.svg";
import CancelButton from "../components/CancelButton";

const FlightPopUpMode = ({ block, index }) => {
  const { flight_type } = block;
  const loading = useSelector(isLoading(flight_type));
  const success = useSelector(isSuccess(flight_type));
  const error = useSelector(isError(flight_type));
  const [step, setStep] = useState(success ? 2 : 1);
  const { getValues } = useFormContext();
  const dispatch = useDispatch();
  const { dates, current_dest } = useSelector(getDatas);

  const onClick = async () => {
    setStep(2);
    dispatch(
      getFlights({
        ...getValues(),
        flight_type: "external",
        dates,
        current_dest,
      })
    );
  };

  const ToStepOne = () => {
    setStep(1);
  };

  return (
    <>
      {step === 1 && (
        <FlightStepOne onClick={onClick} block={block} index={index} />
      )}
      {step === 2 && success && (
        <FlightStepTwo ToStepOne={ToStepOne} block={block} index={index} />
      )}
      {step === 2 && error && (
        <div className="modal">
          <div className="modal-content max-w-300 !p-0">
            <div className="flex text-fs-14 p-6 bg-gold text-center flex-col justify-center items-center rounded-md mb-value-30">
              <img src={warning} alt="" />
              <span className="text-white text-fs-14 block mt-5 font-semibold">
                Aucun vol ne correspond à vos critères ou aux dates de voyage
                sélectionnées. Veuillez modifier votre recherche.
              </span>
              <CancelButton
                block={block}
                index={index}
                classNames={
                  "text-white mt-value-30 text-fs-14 border-b-white flex self-center border-b-1-5 border-accent mx-auto"
                }
              />
            </div>
          </div>
        </div>
      )}
      {loading && <Loading />}
    </>
  );
};

export default FlightPopUpMode;
