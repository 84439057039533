import React from "react";

const Checkboxes = ({
  options,
  label,
  UseControllerProps,
  setValue,
  value,
  room,
  ages = [],
  onClick,
}) => {
  const { field } = UseControllerProps;
  return (
    <>
      {options.map((option, index) => (
        <label
          key={option}
          className={
            "customcheckboxcontainer customcheckboxcontainerwhite main-color font-bold text-fs-14"
          }
          htmlFor={`${option}${room}`}
        >
          {`${label} ${index + 1} ${
            ages.length > 0 ? `( ${ages[index]} ans )` : ""
          }`}
          <input
            onChange={(e) => {
              const valueCopy = [...value];

              // update checkbox value
              valueCopy[index] = e.target.checked ? e.target.value : null;

              // send data to react hook form
              field.onChange(valueCopy);
              onClick();

              // update local state
              setValue(valueCopy);
            }}
            key={option}
            checked={value.includes(option)}
            type="checkbox"
            id={`${option}${room}`}
            value={option}
          />
          <span className="checkmark"></span>
        </label>
      ))}
    </>
  );
};

export default Checkboxes;
