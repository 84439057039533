import React from "react";

import Travelers from "./Travelers";
import DateComponent from "./DateComponent";
import Flights from "./Flights";
import Assurance from "./Assurance";
import Islands from "./Islands";
import Hotels from "./Hotels";
import Options from "./Options";
import Transfert from "./Transfert";
import Excursion from "./Excursions";
import HotelsWrapper from "./HotelsWrapper";
import SpecialRequest from "./SpecialRequest";
import ValidationSection from "./ValidationSection";
import RealTimeQuote from "./RealTimeQuote";

const Components = {
  travelers: Travelers,
  date: DateComponent,
  flight: Flights,
  assurance: Assurance,
  hotel: Hotels,
  transfert: Transfert,
  excursion: Excursion,
  options: Options,
  islands: Islands,
  hotels: HotelsWrapper,
  special_request: SpecialRequest,
  validation_section: ValidationSection,
  real_time_quote: RealTimeQuote,
};

export const transformComponent = (block, index) => {
  // component does exist

  if (typeof Components[block.component] !== "undefined") {
    return React.createElement(Components[block.component], {
      key: index,
      index,
      block: block,
    });
  }
  // component doesn't exist yet
  return React.createElement(
    () => <div>The component {block.component} has not been created yet.</div>,
    { key: index }
  );
};
