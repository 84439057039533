import { React } from "react";
import FlightRecapMode from "../components/FlightRecapMode";
import FlightPopUpMode from "../components/FlightPopUpMode";
import ButtonModeComponent from "../components/ButtonModeComponent";

const Flights = ({ block, index }) => {
  const { mode, disabled } = block;
  return (
    <>
      {mode === "button" && (
        <ButtonModeComponent
          name={"Vol"}
          disabled={disabled}
          block={block}
          index={index}
        />
      )}
      {mode === "recap" && <FlightRecapMode block={block} />}
      {mode === "popup" && <FlightPopUpMode block={block} index={index} />}
    </>
  );
};

export default Flights;
