import { put } from "redux-saga/effects";
import { fetchWithTimeout } from "../helper";

function* fetchAssurance(action) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const { api_key } = action.payload;
  const headers = new Headers();
  if (api_key.length > 0) headers.append("api-key", api_key);
  let request = `${API_ENDPOINT}/api/v1/insurances`;
  try {
    const response = yield fetchWithTimeout(request, { headers });
    if (response.status === 200) {
      const json = yield response.json();
      yield put({ type: "assurance/putAssurance", json });
    } else {
      throw response;
    }
  } catch (e) {
    yield put({ type: "assurance/errorApiAssurance", message: e.message });
  }
}

export { fetchAssurance };
