const getLastKey = (obj) => {
  const keys = Object.keys(obj);
  if (keys.length === 0) {
    // Handle the case where the object has no keys
    return undefined;
  }
  return keys[keys.length - 1];
};

const getFirsttKey = (obj) => {
  const keys = Object.keys(obj);
  if (keys.length === 0) {
    // Handle the case where the object has no keys
    return undefined;
  }
  return keys[0];
};

const RemoveTrailingZero = (Number) => {
  return Math.floor(parseFloat(Number));
};

async function fetchWithTimeout(resource, options) {
  const { timeout = 500000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);

  return response;
}

export { getLastKey, getFirsttKey, RemoveTrailingZero, fetchWithTimeout };
