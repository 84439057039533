import React from "react";
import RoomG from "../components/RoomG";

const RoomGrouped = ({
  content_grouped,
  room,
  onChange,
  onClick,
  radioValue,
}) => {
  const { title, image, teaser, area, hidden, rooms_fares_array } =
    content_grouped;
  return (
    <div className={`${hidden ? "hidden" : "border-b border-b-gray-200"}`}>
      {title !== undefined && (
        <div className="flex flex-col pb-5">
          <div>
            <div className="flex justify-between items-center mt-value-30 mb-2.5">
              <h3 className="merriweather main-color text-fs-18 font-bold">
                {title}
                {area && ` - ${area}`}
              </h3>
            </div>
            <div className="flex rounded-md">
              {typeof image === "object" && "url" in image && (
                <img
                  src={image.url}
                  alt={image.alt}
                  className="min-w-315 h-210 object-cover rounded-md self-start"
                />
              )}
              {teaser !== undefined && (
                <div
                  className={`flex-col ${
                    typeof image === "object" && "url" in image ? "pl-5" : ""
                  }`}
                >
                  <div className="main-color [&>p]:mb-2 [&>p:last-child]:mb-0 text-fs-14 leading-lh-25">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: teaser,
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {(rooms_fares_array || []).map((element) => (
        <RoomG
          key={element[0]}
          room_key={element[0]}
          room_fares={element[1]}
          room={room}
          onChange={onChange}
          radioValue={radioValue}
          onClick={onClick}
        />
      ))}
    </div>
  );
};
export default RoomGrouped;
