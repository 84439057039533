import React from "react";
import { formatRoomTitle } from "../selectors/rooms";

const RoomRecap = ({ data }) => {
  const { lib, formule, adult_number, children_number, image, availability } =
    data;
  return (
    <div className="flex flex-col [&>div]:mb-2.5 [&>div:last-child]:mb-0">
      <div className="flex items-center">
        <div className="bg-grey-pagination rounded-md flex-1 mr-4 py-4 px-5 flex">
          {typeof image === "object" && "url" in image && (
            <img
              src={image["url"]}
              className="object-cover w-190 h-120 rounded-md"
              alt={image["alt"]}
            />
          )}
          <div className="flex flex-col ml-5 justify-end">
            {lib && (
              <span className="merriweather gold-color font-bold text-fs-18 mb-5">
                {formatRoomTitle(lib)}
              </span>
            )}
            <div className="flex text-fs-14 tablet:flex-col">
              <span className="bg-white tablet:self-start flex items-center rounded-md px-4 h-value-30 main-color mr-5 tablet:mt-2.5">
                {adult_number} adulte{adult_number > 1 ? "s" : ""}
              </span>
              <span className="bg-white tablet:self-start flex items-center rounded-md px-4 h-value-30 main-color mr-5 tablet:mt-2.5">
                {children_number} enfant{children_number > 1 ? "s" : ""}
              </span>
              <span className="bg-white tablet:self-start flex items-center rounded-md px-4 h-value-30 main-color mr-5 tablet:mt-2.5 lowercase">
                {formule}
              </span>
            </div>
          </div>
        </div>

        <span className="w-value-100 bg-accent h-26 flex items-center justify-center text-fs-13 rounded-md text-white font-semibold">
          {availability === "false" ? "En demande" : "Disponible"}
        </span>
      </div>
    </div>
  );
};

export default RoomRecap;
