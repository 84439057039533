import React from "react";

const LinkButton = ({ button, link, classNames }) => {
  const onClick = () => {
    window.open(link, "_blank");
  };

  return (
    <button
      className={`${
        classNames !== undefined
          ? classNames
          : "p-16-35 leading-lh-15 block mx-auto main-bg-color text-white br-26 text-fs-14 w-value-226 mt-value-30"
      }`}
      type="button"
      onClick={() => onClick()}
    >
      {button.label}
    </button>
  );
};

export default LinkButton;
