import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import TransfertRow from "./TransfertRow";
import LocationRow from "./LocationRow";

const TransferTab = ({ block }) => {
  const { setValue, register, getValues } = useFormContext();

  const values = getValues();
  const { data, dest } = block;
  const tranfert_name = `transfert.${dest}.code`;
  const naming = {
    transfert: "Transfert",
    location: "Location de voiture",
  };
  const transfert_type =
    "transfert" in values &&
    dest in values["transfert"] &&
    "type" in values["transfert"][dest]
      ? values["transfert"][dest]["type"]
      : undefined;

  const tab = {};

  const { transfert, location } = data;

  for (let key in data) {
    if (Object.keys(data[key]).length !== 0) {
      tab[key] = naming[key];
    }
  }

  const [openTab, setOpenTab] = useState(
    transfert_type !== undefined
      ? transfert_type
      : Object.keys(transfert).length > 0
      ? "transfert"
      : "location"
  );

  useEffect(() => {
    setValue(`transfert.${dest}.type`, openTab);
  }, [openTab, setValue, dest]);
  const sortedTransfertArray = Object.entries(transfert).sort((a, b) =>
    b[0].localeCompare(a[0])
  );
  const sortedTransfert = Object.fromEntries(sortedTransfertArray);
  return (
    <>
      <div className="flex justify-center">
        <input type="hidden" {...register(`transfert.${dest}.type`)} />
        <div className="flex justify-center font-bold cursor-pointer mb-value-40 rounded-md">
          {Object.keys(tab).map((key, index) => {
            return (
              <button
                className={`main-color [&.active]:bg-accent [&.active]:text-white flex items-center justify-center w-value-226 bg-grey-pagination  h-value-60
                 ${openTab === key ? "active " : " "}
                 ${index === 0 ? "rounded-l-md " : " "}
                 ${
                   index + 1 === Object.keys(tab).length ? "rounded-r-md " : " "
                 }`}
                key={key}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(key);
                }}
              >
                {tab[key]}
              </button>
            );
          })}
        </div>
      </div>
      {openTab === "transfert" && (
        <>
          <div className="text-fs-14 main-color block mb-5 smalldesktop:text-center tablet:text-center">
            <span className="font-bold">Ajouter un transfert : </span>
            Aéroport / Hôtel / Aéroport
          </div>
          <div className="flex desktop:justify-between smalldesktop:items-center tablet:items-center tablet:justify-center smalldesktop:flex-col main-color flex-col text-fs-14">
            {Object.keys(sortedTransfert).map((key) => {
              return (
                <TransfertRow
                  key={key}
                  transfer={transfert[key]}
                  name={tranfert_name}
                />
              );
            })}
          </div>
        </>
      )}
      {openTab === "location" && (
        <>
          <div className="text-fs-14 main-color block mb-5 smalldesktop:text-center tablet:text-center">
            <span className="font-bold w-value-172">
              Ajouter une location de voiture prise et rendue à l’aéroport
            </span>
          </div>

          <div className="flex desktop:justify-between smalldesktop:items-center tablet:items-center tablet:justify-center smalldesktop:flex-col main-color flex-wrap text-fs-14">
            {Object.keys(location).map((key) => {
              return (
                <LocationRow
                  key={key}
                  code={key}
                  transfer={location[key]}
                  name={tranfert_name}
                />
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default TransferTab;
