import React from "react";
import ButtonModeComponent from "../components/ButtonModeComponent";
import ValidateButton from "../components/ValidateButton";
import TransferTab from "../components/transfertTab";
import TransfertRecapMode from "../components/TransfertRecapMode";
import CancelButton from "../components/CancelButton";
import { useWatch } from "react-hook-form";

const Transfert = ({ block, index }) => {
  const { mode, disabled, data, dest } = block;
  const tranfert_name = `transfert.${dest}.code`;
  const transfertValue = useWatch({
    name: tranfert_name,
  });
  const tranfert_data = { ...data["transfert"], ...data["location"] };
  return (
    <>
      {mode === "button" && (
        <ButtonModeComponent
          name={"Transfert / Location de voiture"}
          disabled={disabled}
          block={block}
          index={index}
        />
      )}
      {mode === "recap" && (
        <TransfertRecapMode
          data={data}
          code={tranfert_name}
          block={block}
          index={index}
        />
      )}
      {mode === "popup" && (
        <div className="modal">
          <div className="modal-content max-w-1172">
            <div className="flex justify-between pb-5">
              <h3 className="gold-color text-fs-21 leading-lh-37 merriweather font-bold ">
                Transfert / Location de voiture
              </h3>
            </div>
            <TransferTab block={block} />
            <div className="flex flex-col mt-value-30">
              <ValidateButton
                classes={
                  "p-16-35 leading-lh-15 block mx-auto main-bg-color text-white br-26 text-fs-14 w-value-226 disabled:bg-disabled-button"
                }
                button={{
                  label: "Valider",
                  type: "SELECT_TRANSFERT",
                }}
                validate={false}
                block={block}
                index={index}
                disabled={
                  transfertValue === undefined ||
                  transfertValue === null ||
                  !(transfertValue in tranfert_data)
                }
              />
              <CancelButton block={block} index={index} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Transfert;
