import React from "react";
import { useDispatch } from "react-redux";
import { editMode } from "../actions";
import { useFormContext } from "react-hook-form";

const EditButton = ({ block, index, classNames }) => {
  const dispatch = useDispatch();
  const { getValues, clearErrors, unregister } = useFormContext();

  return (
    <button
      type="button"
      className={`${
        classNames !== undefined
          ? classNames
          : "main-color text-fs-14 border-b-main-color flex self-center border-b-1-5 border-accent"
      }`}
      onClick={(e) => {
        e.preventDefault();
        clearErrors();
        dispatch(editMode({ ...getValues(), block, index, unregister }));
      }}
    >
      Modifier
    </button>
  );
};

export default EditButton;
