import React from "react";
import adult from "../img/icons/adult.png";
import kid from "../img/icons/kid.svg";
import baby from "../img/icons/baby.png";
import suites from "../img/icons/suites-villas.png";
import TooltipHotel from "./TooltipHotel";
import { formatRoomTitle } from "../selectors/rooms";

const RoomG = ({
  room_fares,
  room_key,
  room,
  onChange,
  radioValue,
  onClick,
}) => {
  const {
    lib,
    capamax_bb,
    capamax_adulte,
    capamax_enfant,
    capa_maxi,
    age_max,
    age_min,
    hidden,
    type_prix,
    prix,
    availability,
  } = room_fares;

  return (
    <div className={`flex flex-col pb-5 ${hidden ? "hidden" : ""}`}>
      <div>
        <div className="flex justify-between items-center mt-5 mb-2.5">
          <div className="flex flex-col">
            <div className="flex mb-5">
              <img className="mr-5 max-w-43" src={suites} alt="" />
              {lib && (
                <h3 className="merriweather gold-color text-fs-18 font-bold">
                  {formatRoomTitle(lib)}
                </h3>
              )}
            </div>
            <div className="flex main-color text-fs-14 font-semibold">
              <div className="flex">
                <span className="mr-value-5">Capacité Maximale</span>
                <span>{capa_maxi}</span>
              </div>
              <div className="flex ml-value-30 ">
                <TooltipHotel
                  src={adult}
                  data={capamax_adulte}
                  id={`adule${room_key}`}
                  active={false}
                />
                {capamax_enfant !== 0 && (
                  <TooltipHotel
                    src={kid}
                    data={capamax_enfant}
                    id={`kid${room_key}`}
                    content={`De ${age_min} à ${age_max} ans`}
                    image_classnames={"h-3.5 mr-value-5"}
                    active={true}
                  />
                )}
                {capamax_bb !== 0 && (
                  <TooltipHotel
                    src={baby}
                    data={capamax_bb}
                    id={`bb${room_key}`}
                    content={`Inférieur à ${age_min} ans`}
                    image_classnames={"h-3.5 mr-value-5"}
                    active={true}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="flex items-end flex-col">
            <span className="w-value-100 mr-3 bg-gold h-26 flex items-center justify-center text-fs-13 rounded-md text-white font-semibold mb-2.5">
              {availability === "false" ? "En demande" : "Disponible"}
            </span>
            <div className="flex items-center">
              <div className="flex items-end mr-5 main-color">
                <span className="text-fs-16 leading-lh-19 font-bold">
                  {prix}€ /{type_prix === "PAR CHAMBRE" ? "Chb." : "Pers."}
                </span>
                <span className="text-fs-10 leading-lh-12 ml-1.5">
                  (hors offres)
                </span>
              </div>
              <div className="customradiobutton relative cursor-pointer ml-5">
                <input
                  id={`${room_key}${room}`}
                  value={room_key}
                  onChange={onChange}
                  onClick={() => {
                    if (radioValue.length > 0) onClick();
                  }}
                  checked={room_key === radioValue}
                  type="radio"
                />
                <label htmlFor={`${room_key}${room}`}>Sélectionner</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RoomG;
