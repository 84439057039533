import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getDatas } from "../selectors/userSelection";
import {
  getDepartureCityLabel,
  getDestination,
} from "../selectors/destination";
import { getRealTimeApi, isSuccess, isLoading } from "../selectors/realTime";
import { format, differenceInDays } from "date-fns";
import arrow_down from "../img/icons/arrow-down-white.svg";

const RealTimeQuote = ({ block }) => {
  const { mode } = block;
  const [showMore, setShowMore] = useState(true);
  const {
    adults,
    children,
    staydates: { startDate, endDate },
    flight,
    outboundDate,
    inboundDate,
    dest,
    next_dest,
  } = useSelector(getDatas);
  const today = format(new Date(), "dd/MM/yyyy");
  const start_date = format(new Date(outboundDate), "dd/MM/yyyy");
  const end_date = format(new Date(inboundDate), "dd/MM/yyyy");
  const days_difference = differenceInDays(
    new Date(endDate),
    new Date(startDate)
  );

  let departure_city_code = "";
  let nbr_days = days_difference;
  const { label_destination, combinaison_destinations } = useSelector(
    getDestination(dest)
  );

  if (typeof flight === "object" && "external" in flight) {
    let { total_lag, departure_city } = flight["external"];
    nbr_days += total_lag;
    departure_city_code = departure_city;
  }

  const departure_city_label = useSelector(
    getDepartureCityLabel(departure_city_code)
  );

  const Success = useSelector(isSuccess);
  const Loading = useSelector(isLoading);
  const { montant } = useSelector(getRealTimeApi);

  return (
    <>
      {mode === "show" && (
        <div
          className={`real_quote fixed bottom-0 left-0 w-full bg-accent text-white text-fs-14 z-20 transition duration-500 group  ${
            showMore === true ? "translate-y-expand-fixed-bottom showMore" : ""
          }`}
        >
          <div className="flex">
            <div className="flex justify-between flex-1 px-value-40 py-5">
              <div className="flex flex-col">
                <span className="block mb-2.5">
                  Devis du {today}
                  {" - "}
                  {label_destination}
                  {next_dest !== undefined && next_dest !== "none" && (
                    <> {` &  ${combinaison_destinations[next_dest]}`}</>
                  )}
                </span>
                <div className="flex desktop:items-center mb-2.5 tablet:flex-col">
                  <div className="flex items-center tablet:mb-2.5">
                    <span className="font-bold w-value-100 mr-5 whitespace-nowrap">
                      Départ / Retour
                    </span>
                    <div className="flex">
                      <span className="flex items-center h-value-30 rounded-md bg-accent-muted px-4">
                        {start_date} - {end_date}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <span className="font-bold w-value-100 mr-5 desktop:ml-value-40 whitespace-nowrap">
                      Durée du voyage
                    </span>
                    <div className="flex">
                      <span className="flex items-center h-value-30 rounded-md bg-accent-muted px-4">
                        {nbr_days} jours / {days_difference} nuits
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex items-center mb-2.5 group-[.translate-y-expand-fixed-bottom]:opacity-0  transition duration-500">
                  <span className="font-bold w-value-100 mr-5 whitespace-nowrap">
                    Voyageurs
                  </span>
                  <div className="flex [&>span]:ml-5 [&>span:first-child]:ml-0">
                    <span className="flex items-center h-value-30 rounded-md bg-accent-muted px-4">
                      {adults} adultes
                    </span>
                    {children !== 0 && (
                      <span className="flex items-center h-value-30 rounded-md bg-accent-muted px-4">
                        {children} enfants
                      </span>
                    )}
                  </div>
                </div>
                {typeof departure_city_label === "string" &&
                  departure_city_label.length > 0 && (
                    <div className="flex items-center group-[.translate-y-expand-fixed-bottom]:opacity-0 transition duration-500">
                      <span className="font-bold w-value-100 mr-5 whitespace-nowrap">
                        Ville de départ
                      </span>

                      <div className="flex [&>span]:ml-5 [&>span:last-child]:ml-0">
                        <span className="flex items-center h-value-30 rounded-md bg-accent-muted px-4">
                          {departure_city_label}
                        </span>
                      </div>
                    </div>
                  )}
              </div>
              {Success && montant !== undefined && (
                <div className="flex flex-col">
                  <div className="flex items-center desktop:pt-4 tablet:pt-37">
                    <span>Montant total ttc</span>
                    <span className="text-fs-18 font-bold ml-5">
                      {montant.total} €
                    </span>
                  </div>
                  <div className="pt-5 flex-col flex-col border-t border-t-accent-muted mt-auto group-[.translate-y-expand-fixed-bottom]:opacity-0 transition duration-500">
                    <div className="flex justify-between mb-2.5">
                      <span>Forfait</span>
                      <span className="font-bold"> {montant.forfait} €</span>
                    </div>
                    {montant.tax.length > 0 && (
                      <div className="flex justify-between">
                        <span>Taxes</span>
                        <span className="font-bold"> {montant.tax} €</span>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {Loading && (
                <div className="flex flex-col">
                  <div className="flex items-center desktop:pt-4 tablet:pt-37">
                    <span>Montant total ttc</span>
                    <span className="text-fs-18 font-bold ml-5">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <circle cx="4" cy="12" r="3" fill="currentColor">
                          <animate
                            id="svgSpinners3DotsFade0"
                            fill="freeze"
                            attributeName="opacity"
                            begin="0;svgSpinners3DotsFade1.end-0.25s"
                            dur="0.75s"
                            values="1;.2"
                          />
                        </circle>
                        <circle
                          cx="12"
                          cy="12"
                          r="3"
                          fill="currentColor"
                          opacity=".4"
                        >
                          <animate
                            fill="freeze"
                            attributeName="opacity"
                            begin="svgSpinners3DotsFade0.begin+0.15s"
                            dur="0.75s"
                            values="1;.2"
                          />
                        </circle>
                        <circle
                          cx="20"
                          cy="12"
                          r="3"
                          fill="currentColor"
                          opacity=".3"
                        >
                          <animate
                            id="svgSpinners3DotsFade1"
                            fill="freeze"
                            attributeName="opacity"
                            begin="svgSpinners3DotsFade0.begin+0.3s"
                            dur="0.75s"
                            values="1;.2"
                          />
                        </circle>
                      </svg>
                    </span>
                  </div>
                  <div className="pt-5 flex-col flex-col border-t border-t-accent-muted mt-auto group-[.translate-y-expand-fixed-bottom]:opacity-0 transition duration-500">
                    <div className="flex justify-between mb-2.5">
                      <span>Forfait</span>
                      <span className="font-bold">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <circle cx="4" cy="12" r="3" fill="currentColor">
                            <animate
                              id="svgSpinners3DotsFade0"
                              fill="freeze"
                              attributeName="opacity"
                              begin="0;svgSpinners3DotsFade1.end-0.25s"
                              dur="0.75s"
                              values="1;.2"
                            />
                          </circle>
                          <circle
                            cx="12"
                            cy="12"
                            r="3"
                            fill="currentColor"
                            opacity=".4"
                          >
                            <animate
                              fill="freeze"
                              attributeName="opacity"
                              begin="svgSpinners3DotsFade0.begin+0.15s"
                              dur="0.75s"
                              values="1;.2"
                            />
                          </circle>
                          <circle
                            cx="20"
                            cy="12"
                            r="3"
                            fill="currentColor"
                            opacity=".3"
                          >
                            <animate
                              id="svgSpinners3DotsFade1"
                              fill="freeze"
                              attributeName="opacity"
                              begin="svgSpinners3DotsFade0.begin+0.3s"
                              dur="0.75s"
                              values="1;.2"
                            />
                          </circle>
                        </svg>
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span>Taxes</span>
                      <span className="font-bold">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <circle cx="4" cy="12" r="3" fill="currentColor">
                            <animate
                              id="svgSpinners3DotsFade0"
                              fill="freeze"
                              attributeName="opacity"
                              begin="0;svgSpinners3DotsFade1.end-0.25s"
                              dur="0.75s"
                              values="1;.2"
                            />
                          </circle>
                          <circle
                            cx="12"
                            cy="12"
                            r="3"
                            fill="currentColor"
                            opacity=".4"
                          >
                            <animate
                              fill="freeze"
                              attributeName="opacity"
                              begin="svgSpinners3DotsFade0.begin+0.15s"
                              dur="0.75s"
                              values="1;.2"
                            />
                          </circle>
                          <circle
                            cx="20"
                            cy="12"
                            r="3"
                            fill="currentColor"
                            opacity=".3"
                          >
                            <animate
                              id="svgSpinners3DotsFade1"
                              fill="freeze"
                              attributeName="opacity"
                              begin="svgSpinners3DotsFade0.begin+0.3s"
                              dur="0.75s"
                              values="1;.2"
                            />
                          </circle>
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              className="w-value-100 flex justify-center cursor-pointer border-l border-l-accent-muted"
              onClick={() => setShowMore(!showMore)}
            >
              <img
                className="max-w-17 self-start desktop:mt-45 tablet:mt-67 group-[.translate-y-expand-fixed-bottom]:rotate-180"
                src={arrow_down}
                alt=""
              />
            </div>
          </div>
        </div>
      )}
      {mode === "show" && <></>}
    </>
  );
};

export default RealTimeQuote;
