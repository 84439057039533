import React from "react";
import { useFormContext } from "react-hook-form";

const ButtonCheckbox = ({ id }) => {
  const { register } = useFormContext();
  return (
    <div className="customradiobutton relative cursor-pointer ml-5">
      <input {...register("options")} type="checkbox" id={id} value={id} />
      <label className="checkmark" htmlFor={id}>
        Sélectionner
      </label>
    </div>
  );
};

export default ButtonCheckbox;
