import { createSlice } from "@reduxjs/toolkit";

const assuranceSlice = createSlice({
  name: "assurance",
  initialState: {
    assurance: {},
    message: "",
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  reducers: {
    fetchAssurance: (state) => {
      state.isLoading = true;
    },
    putAssurance: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.assurance = action.json.insurances;
    },
    errorApiAssurance: (state, action) => {
      state.message = action.message;
      state.isLoading = false;
      state.isError = true;
    },
  },
});

export const assuranceReducer = assuranceSlice.reducer;

export const { fetchAssurance, putAssurance } = assuranceSlice.actions;
