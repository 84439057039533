import React from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import { cancel } from "../actions";

const CancelButton = ({ block, index, classNames }) => {
  const { getValues } = useFormContext();

  const dispatch = useDispatch();
  const onClick = (e) => {
    e.preventDefault();
    dispatch(cancel({ ...getValues(), block, index }));
  };

  return (
    <button
      className={`${
        classNames !== undefined
          ? classNames
          : "main-color mt-value-30 text-fs-14 border-b-main-color flex self-center border-b-1-5 border-accent mx-auto"
      }`}
      onClick={onClick}
    >
      Annuler
    </button>
  );
};

export default CancelButton;
