import { createSlice } from "@reduxjs/toolkit";

const roomsSlice = createSlice({
  name: "rooms",
  initialState: {
    rooms: {},
    errors: {},
    isLoading: {},
    isSuccess: {},
    isError: {},
  },
  reducers: {
    fetchRooms: (state, action) => {
      const code = action.payload.code_hotel;
      state.isLoading[code] = true;
      if (code in state.errors) {
        delete state.errors[code];
      }
    },
    putRooms: (state, action) => {
      const code = action.code;
      state.isLoading[code] = false;
      state.isSuccess[code] = true;
      state.rooms[code] = action.json;
    },
    errorApiRooms: (state, action) => {
      const code = action.code;
      state.isLoading[code] = false;
      state.isError[code] = true;
      state.errors[code] = action.message;
    },
    resetRooms: (state) => {
      state.rooms = {};
      state.errors = {};
      state.isLoading = {};
      state.isSuccess = {};
      state.isError = {};
    },
  },
});

export const roomsReducer = roomsSlice.reducer;

export const { fetchRooms, resetRooms } = roomsSlice.actions;
