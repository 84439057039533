import React from "react";
import { getDatas } from "../selectors/userSelection";
import { useSelector } from "react-redux";
import calender from "../img/icons/calendar.png";
import EditButton from "../components/EditButton";
import formatDate from "../validation/formateDate";

const DateRecapMode = ({ block, index }) => {
  const {
    staydates: { startDate, endDate },
  } = useSelector(getDatas);

  return (
    <div className="bg-white p-5 rounded-md">
      <div className="flex justify-between items-center mb-5">
        <h3 className="gold-color text-fs-21 leading-lh-27 merriweather font-bold">
          Dates
        </h3>
        <EditButton block={block} index={index} />
      </div>
      <div className="bg-grey-pagination rounded-md px-5 py-14 flex justify-between main-color mb-2.5">
        <div className="flex items-center">
          <span className="text-fs-14 font-bold">Dates de voyage</span>
          <div className="bg-white rounded-md flex items-center h-value-30 cursor-pointer px-5 ml-5">
            <img className="w-15 h-15" src={calender} alt="calendar" />
            <span className="text-fs-14 ml-2.5">{formatDate(startDate)}</span>
          </div>
          <div className="bg-white rounded-md flex items-center h-value-30 cursor-pointer px-5 ml-5">
            <img className="w-15 h-15" src={calender} alt="calendar" />
            <span className="text-fs-14 ml-2.5">{formatDate(endDate)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateRecapMode;
