import React from "react";
import { useController, useFormContext } from "react-hook-form";

const Select = ({
  id,
  options,
  default_value = "",
  isDisabled = false,
  ClassNames,
  validate,
}) => {
  const { clearErrors } = useFormContext();
  const controllerProps = {
    name: id,
    defaultValue: default_value,
    rules: {},
  };
  if (validate) {
    controllerProps["rules"]["validate"] = validate;
  }

  const { field } = useController(controllerProps);

  return (
    <select
      id={id}
      disabled={isDisabled}
      onChange={(e) => {
        clearErrors();
        field.onChange(e);
      }}
      onBlur={field.onBlur} // notify when input is touched/blur
      value={field.value} // input value
      name={field.name} // send down the input name
      ref={field.ref} // send input ref, so we can focus on input when error appear
      className={`${
        ClassNames
          ? ClassNames
          : "custom-select-arrow border border-gray-200 rounded-md"
      }`}
    >
      {options.map((option, key) => (
        <option value={option.value} key={key}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default Select;
