import React from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import { appBrain } from "../actions";

const ValidateButton = ({
  classes,
  button,
  disabled,
  block = {},
  validateStrings = [],
  validate = true,
  index,
  extra_data,
}) => {
  const { getValues, trigger, clearErrors } = useFormContext();

  const dispatch = useDispatch();
  const onClick = async () => {
    clearErrors();
    let result = true;
    if (validateStrings.length > 0) {
      result = await trigger(validateStrings);
    }
    if (!validate || result) {
      dispatch(appBrain({ ...getValues(), button, block, index, extra_data }));
    }
  };

  return (
    <button
      className={`${classes ? classes : ""}`}
      type="button"
      onClick={() => onClick()}
      disabled={disabled}
    >
      {button.label}
    </button>
  );
};

export default ValidateButton;
