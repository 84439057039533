import React from "react";
import { useFormContext, useWatch } from "react-hook-form";

export const useOnceCall = (cb, condition = true) => {
  const isCalledRef = React.useRef(false);
  React.useEffect(() => {
    if (condition && !isCalledRef.current) {
      isCalledRef.current = true;
      cb();
    }
  }, [cb, condition]);
};

export const useFormValues = () => {
  const { getValues } = useFormContext();
  return {
    ...useWatch(), // subscribe to form value updates
    ...getValues(), // always merge with latest form values
  };
};
