import { all, takeLatest, takeEvery } from "redux-saga/effects";
import { fetchFlights } from "./flights";
import { fetchDestination } from "./destination";
import { fetchProducts } from "./products";
import { fetchRooms } from "./rooms";
import { fetchAssurance } from "./assurance";
import { fetchRealTime } from "./real_time";

function* actionWatcher() {
  yield takeLatest("flight/getFlights", fetchFlights);
  yield takeLatest("destination/fetchDestination", fetchDestination);
  yield takeEvery("products/getProducts", fetchProducts);
  yield takeLatest("rooms/fetchRooms", fetchRooms);
  yield takeLatest("assurance/fetchAssurance", fetchAssurance);
  yield takeLatest("real_time/fetchRealTime", fetchRealTime);
}
export default function* rootSaga() {
  yield all([actionWatcher()]);
}
