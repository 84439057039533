import React from "react";
import { RemoveTrailingZero } from "../helper";
import { getDatas } from "../selectors/userSelection";
import { useSelector } from "react-redux";

const AssuranceRecapMode = ({ assurances }) => {
  const { assurance } = useSelector(getDatas);
  const data = assurances[assurance];
  return (
    <div className="rounded-md bg-grey-pagination p-5">
      <div className="flex bg-white main-color desktop:items-center p-5 rounded-md tablet:flex-col">
        <div className="flex flex-col flex-1">
          <span className="font-bold mb-2.5 text-fs-14">{data?.title}</span>
          <span
            className="text-fs-14 leading-lh-18"
            dangerouslySetInnerHTML={{ __html: data?.b2b_description }}
          />
          {/* <span
            className="text-fs-14 leading-lh-18"
            dangerouslySetInnerHTML={{ __html: data.b2c_description }}
          /> */}
        </div>
        {assurance !== "none" ? (
          <div className="flex items-center tablet:mt-5 tablet:justify-center ml-value-60">
            <div className="flex flex-col">
              <span className="text-fs-12 font-semibold">À partir de</span>
              <div className="flex items-center">
                <span className="text-fs-21 leading-lh-32 font-bold">
                  {RemoveTrailingZero(data.price)} €
                </span>
                <span className="text-fs-12 pl-5px leading-lh-15">/ Pers.</span>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default AssuranceRecapMode;
