import { put } from "redux-saga/effects";
import { fetchWithTimeout } from "../helper";

function* fetchFlights(action) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const data = action.payload;

  const {
    dest,
    next_dest,
    current_dest,
    staydates: { startDate, endDate },
    flight_type,
    dates,
    api_key,
  } = data;

  const is_mutidest =
    next_dest !== undefined &&
    next_dest !== "none" &&
    current_dest === next_dest
      ? true
      : false;
  const trip = is_mutidest === true ? "openjaw" : "AR";
  const { departureCity, cabin_aller, cabin_retour } =
    data["flight"][flight_type];
  let request = `${API_ENDPOINT}/api/flights_resource?trip=` + trip;
  request += "&pax[adt]=" + data["adults"];
  if (data["children"] > 0) {
    let children = 0;
    let babies = 0;
    [...Array(parseInt(data["children"]))].forEach((x, i) => {
      data[`age_${i + 1}`] < 2 ? babies++ : children++;
    });
    if (children > 0) {
      request += "&pax[chd]=" + children;
    }
    if (babies > 0) {
      request += "&pax[inf]=" + babies;
    }
  }

  const inter_dest_arrival = is_mutidest === true ? next_dest : departureCity;
  const date_inter_dest =
    is_mutidest === true ? dates[dest][0]["end_day"] : endDate;

  request +=
    "&segments[0][from]=" +
    departureCity +
    "&segments[0][date]=" +
    startDate +
    "&segments[0][cabin]=" +
    cabin_aller +
    "&segments[0][to]=" +
    dest;

  request +=
    "&segments[1][from]=" +
    dest +
    "&segments[1][to]=" +
    inter_dest_arrival +
    "&segments[1][date]=" +
    date_inter_dest +
    "&segments[1][cabin]=" +
    cabin_retour;

  if (is_mutidest === true) {
    request +=
      "&segments[2][from]=" +
      next_dest +
      "&segments[2][to]=" +
      departureCity +
      "&segments[2][date]=" +
      endDate +
      "&segments[2][cabin]=" +
      cabin_retour;
  }

  const headers = new Headers();
  if (api_key.length > 0) headers.append("api-key", api_key);
  try {
    const response = yield fetchWithTimeout(request, { headers });
    if (response.status === 200) {
      const json = yield response.json();
      yield put({ type: "flight/putFlights", json, flight_type });
    } else {
      throw response;
    }
  } catch (e) {
    yield put({
      type: "flight/errorApiFlights",
      message: e.message,
      flight_type,
    });
  }
}

export { fetchFlights };
