import React, { useEffect } from "react";
import InputNumber from "../components/InputNumber";
import Repartition from "./Repartition";
import { getAvailableDays, isMultiDesti } from "../selectors/userSelection";
import { IsMultiIsland } from "../selectors/destination";
import { useSelector } from "react-redux";
import {
  RoomCodeExist,
  ErrorCodeExist,
  isLoading,
  isSuccess,
} from "../selectors/rooms";
import { getDataFormattedRroduct, getValidOffers } from "../selectors/products";
import { useFormValues } from "../hooks";
import { useFormContext } from "react-hook-form";

const RoomTab = ({
  code_hotel,
  pensions_data,
  images,
  teaser,
  offers,
  url,
  icons,
  block,
  form_only,
  rooms_edito,
  bundle,
  resa_info,
}) => {
  const { num, dest, index_parent, flag } = block;
  const values = useFormValues();
  const { register, setValue } = useFormContext();
  const diffDay = useSelector(getAvailableDays);
  const valid_offers = useSelector(getValidOffers(offers));
  const product = useSelector(getDataFormattedRroduct(block));
  const max_nights =
    Object.keys(product).length > 0 ? product["nbr_nights"] + diffDay : diffDay;
  const is_multidest = useSelector((state) => isMultiDesti(state));
  const is_multi_island = useSelector((state) => IsMultiIsland(dest)(state));
  const default_nights_value =
    is_multidest === true || is_multi_island === "1" ? 1 : max_nights;
  const children_number = values["children"];
  let count_babies = 0;
  [...Array(parseInt(children_number))].forEach((_, i) => {
    let age = values[`age_${i + 1}`];
    if (age === "<2") count_babies += 1;
  });
  const maxRooms =
    parseInt(children_number) + parseInt(values["adults"]) - count_babies;

  const RoomsExist = useSelector((state) => RoomCodeExist(state, code_hotel));
  const ErrorExist = useSelector((state) => ErrorCodeExist(state, code_hotel));
  const is_loading = useSelector(isLoading(code_hotel));
  const is_success = useSelector(isSuccess(code_hotel));
  const label_hotel = `code_hotel_${index_parent}_${num}`;
  const selected_code = values[label_hotel];

  const nbr_rooms =
    `${index_parent}` in values &&
    num in values[index_parent] &&
    `${code_hotel}` in values[index_parent][num] &&
    "nbr_rooms" in values[index_parent][num][code_hotel]
      ? values[index_parent][num][code_hotel]["nbr_rooms"]
      : 1;

  const nbr_nights =
    `${index_parent}` in values &&
    num in values[index_parent] &&
    `${code_hotel}` in values[index_parent][num] &&
    "nbr_nights" in values[index_parent][num][code_hotel]
      ? values[index_parent][num][code_hotel]["nbr_nights"]
      : default_nights_value;

  const left_nights = max_nights - nbr_nights;
  useEffect(() => {
    if (nbr_nights > max_nights)
      setValue(`${index_parent}.${num}.${code_hotel}.nbr_nights`, max_nights);
  }, [setValue, index_parent, num, code_hotel, max_nights, nbr_nights]);

  return (
    <>
      {is_loading && (
        <div className="flex w-full justify-center items-center mt-100">
          <svg
            id="loading-spinner"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
          >
            <g fill="none">
              <path
                id="track"
                fill="#F2F3F6"
                d="M24,48 C10.745166,48 0,37.254834 0,24 C0,10.745166 10.745166,0 24,0 C37.254834,0 48,10.745166 48,24 C48,37.254834 37.254834,48 24,48 Z M24,44 C35.045695,44 44,35.045695 44,24 C44,12.954305 35.045695,4 24,4 C12.954305,4 4,12.954305 4,24 C4,35.045695 12.954305,44 24,44 Z"
              />
              <path
                id="section"
                fill="#0D2768"
                d="M24,0 C37.254834,0 48,10.745166 48,24 L44,24 C44,12.954305 35.045695,4 24,4 L24,0 Z"
              />
            </g>
          </svg>
        </div>
      )}
      {is_success && selected_code === code_hotel && (
        <>
          <div className="flex justify-between pb-value-40 border-b border-b-gray-200">
            <div>
              <div className="flex features-list pb-value-30 [&>span]:mr-4">
                {(icons || []).map((icon, index) => {
                  return (
                    icon.url !== null && (
                      <span
                        key={index}
                        className="relative inline-block duration-300 cursor-pointer tooltip-container-left"
                      >
                        <span className="flex items-center justify-center max-w-55 w-55 h-55 rounded-full bg-creme">
                          <img
                            className="max-w-45 max-h-35"
                            src={icon.url}
                            alt={icon.alt}
                          />
                        </span>
                        {icon.title !== null ? (
                          <span className="tooltip-content absolute hidden whitespace-nowrap -bottom-0 w-auto text-fs-13 font-semibold shadow-tooltip z-1 rounded-full text-center bg-white main-color">
                            {icon.title}
                          </span>
                        ) : (
                          <></>
                        )}
                      </span>
                    )
                  );
                })}
              </div>
              {typeof teaser === "object" && "text" in teaser && (
                <div
                  className="text-fs-14 main-color leading-lh-24 mb-2.5 text-justify"
                  dangerouslySetInnerHTML={{ __html: teaser.text }}
                ></div>
              )}
              {typeof resa_info === "string" && (
                <div
                  className="text-fs-14 main-color leading-lh-24 mb-2.5 text-justify"
                  dangerouslySetInnerHTML={{ __html: resa_info }}
                ></div>
              )}
              {typeof valid_offers === "object" &&
                Object.keys(valid_offers).length > 0 && (
                  <>
                    {(Object.keys(valid_offers) || []).map((key) => {
                      return (
                        <span
                          key={key}
                          className="h-53 mb-3 flex items-center bg-grey-pagination rounded-md font-bold main-color px-5 text-fs-14"
                          dangerouslySetInnerHTML={{
                            __html: valid_offers[key]["field_description"],
                          }}
                        ></span>
                      );
                    })}
                  </>
                )}
              {form_only !== "1" && bundle === "product" && (
                <a
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                  className="block main-color text-fs-14 underline"
                >
                  Voir l’hôtel
                </a>
              )}
            </div>

            <img
              className="max-w-220 h-value-132 ml-value-87 object-cover self-start rounded"
              src={images !== undefined ? images[0]?.url : null}
              alt=""
            />
          </div>

          <div className="py-value-30">
            <h3 className="merriweather gold-color text-fs-18 font-bold mb-5">
              {` Renseignez la quantité de chambres et le nombre de nuits souhaitées pour cet hôtel`}
            </h3>
            <div className="flex justify-between">
              <div className="flex">
                <div className="flex items-center">
                  <span className="main-color text-fs-14 mr-value-24 font-semibold">
                    Qté chambre
                  </span>
                  <InputNumber
                    id={`${index_parent}.${num}.${code_hotel}.nbr_rooms`}
                    default_value={1}
                    min={1}
                    max={maxRooms}
                  />
                </div>
                <div className="flex items-center ml-value-40">
                  <span className="main-color text-fs-14 mr-value-24 font-semibold">
                    Nb de nuit(s)
                  </span>
                  <InputNumber
                    id={`${index_parent}.${num}.${code_hotel}.nbr_nights`}
                    default_value={default_nights_value}
                    min={1}
                    max={max_nights}
                  />
                </div>
                {left_nights !== 0 && (
                  <div className="flex items-center ml-value-40">
                    <span className="main-color text-fs-14 mr-value-24 font-semibold">
                      reste {left_nights} nuit(s)
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          {selected_code === code_hotel &&
            [...Array(parseInt(nbr_rooms))].map((_, i) => (
              <Repartition
                key={i}
                room={i}
                num={num}
                code_hotel={code_hotel}
                rooms_edito={rooms_edito}
                pensions_data={pensions_data}
                nbr_nights={nbr_nights}
                register={register}
                index_parent={index_parent}
                flag={flag}
              />
            ))}
        </>
      )}
      {!RoomsExist && ErrorExist && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-6"
          role="alert"
        >
          <span className="block sm:inline uppercase tracking-wide text-xs font-bold">
            {`  Erreur lors de la recheche des chambres.`}
          </span>
        </div>
      )}
    </>
  );
};

export default RoomTab;
