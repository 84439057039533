import React, { memo } from "react";
import Checkboxes from "./Checkboxes";
import RoomGrouped from "../components/RoomGrouped";
import Select from "./Select";
import { useFormContext, useController, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { getRooms } from "../selectors/rooms";
import { validateRoom } from "../validation";

const Repartition = ({
  room,
  code_hotel,
  num,
  pensions_data,
  rooms_edito,
  nbr_nights,
  index_parent,
  flag,
}) => {
  const { getValues, clearErrors } = useFormContext();
  const adults_number = getValues("adults");
  const children_number = getValues("children");
  const repartition_adults = getValues(
    `${index_parent}.${num}.${code_hotel}.room.${room}.repartition.adults`
  );
  const repartition_children = getValues(
    `${index_parent}.${num}.${code_hotel}.room.${room}.repartition.children`
  );

  let default_values_adults = [];

  let adults = [];
  let children = [];
  let ages = [];
  let watched_ages = [];
  let count_children = 0;
  let count_adults = 0;

  [...Array(parseInt(adults_number))].forEach((_, i) => {
    adults.push(`adult_${i + 1}`);
  });

  if (children_number === 0 && adults_number < 3 && room === 0) {
    default_values_adults = [...adults];
  }

  [...Array(parseInt(children_number))].forEach((_, i) => {
    let age = getValues(`age_${i + 1}`);
    children.push(`children_${i + 1}`);
    ages.push(age);
  });

  const field_adult = useController({
    name: `${index_parent}.${num}.${code_hotel}.room.${room}.repartition.adults`,
    defaultValue: default_values_adults,
  });

  const field_children = useController({
    name: `${index_parent}.${num}.${code_hotel}.room.${room}.repartition.children`,
    defaultValue: [],
  });

  const [valueAdults, setValueAdults] = React.useState(
    repartition_adults || default_values_adults
  );

  const [valueChildren, setValueChildren] = React.useState(
    repartition_children || []
  );

  valueChildren.forEach((value, key) => {
    if (value) {
      watched_ages.push(ages[key]);
      count_children += 1;
    }
  });

  valueAdults.forEach((value) => {
    if (value) count_adults += 1;
  });

  const roomsData = useSelector((state) => state.roomsReducer.rooms);

  const { rooms, content } =
    code_hotel in roomsData ? roomsData[code_hotel] : {};

  const { field } = useController({
    name: `${index_parent}.${num}.${code_hotel}.room.${room}.code`,
    defaultValue: "",
    rules: {
      validate: (value, formValues) =>
        validateRoom(
          value,
          formValues,
          filteredRooms,
          room,
          rooms,
          nbr_nights,
          flag
        ),
    },
  });
  const room_selected = field.value;

  const onSelectRoom = () => {
    field.onChange("");
    clearErrors();
  };

  const { filteredRooms, contents_sorted_array } = getRooms(
    rooms,
    count_adults,
    count_children,
    watched_ages,
    content,
    room_selected
  );
  const default_formule = Object.keys(pensions_data)[0];
  let formule_value = useWatch({
    name: `${index_parent}.${num}.${code_hotel}.room.${room}.formule`,
  });
  formule_value = formule_value === undefined ? default_formule : formule_value;

  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-col border-gray-200 border p-5 rounded-md mb-value-30">
          <div className="border-b border-b-gray-200 pb-value-30">
            <h3 className="merriweather gold-color text-fs-18 font-bold mb-5">
              {`Chambre  ${room + 1}`}
            </h3>

            <div className="flex items-start rounded-md">
              <div className="flex flex-wrap [&>label]:mr-value-30 [&>label:last-child]:mr-0">
                <Checkboxes
                  name={field_adult.field.name}
                  options={adults}
                  label={"Adulte"}
                  UseControllerProps={field_adult}
                  setValue={setValueAdults}
                  value={valueAdults}
                  room={room}
                  onClick={onSelectRoom}
                />
                <Checkboxes
                  name={field_children.field.name}
                  options={children}
                  label={"Enfant"}
                  UseControllerProps={field_children}
                  setValue={setValueChildren}
                  value={valueChildren}
                  room={room}
                  ages={ages}
                  onClick={onSelectRoom}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            {typeof rooms_edito === "object" && "teaser" in rooms_edito && (
              <div className="main-color [&>p]:mb-2 [&>p:last-child]:mb-0 text-fs-14 leading-lh-25 mt-value-15">
                <p
                  dangerouslySetInnerHTML={{
                    __html: rooms_edito["teaser"],
                  }}
                />
              </div>
            )}
            {(contents_sorted_array || []).map((content) => (
              <RoomGrouped
                key={content[0]}
                content_grouped={content[1]}
                room={room}
                onChange={(e) => {
                  // send data to react hook form
                  clearErrors();
                  field.onChange(e);
                }}
                onClick={onSelectRoom}
                radioValue={field.value}
              />
            ))}
          </div>

          <div className="pt-value-30 pb-2.5 flex items-center">
            <span className="main-color text-fs-14 font-semibold mr-value-24 self-start whitespace-nowrap mt-2.5">
              Formule de repas
            </span>
            <div className="flex flex-col">
              <Select
                id={`${index_parent}.${num}.${code_hotel}.room.${room}.formule`}
                options={Object.entries(pensions_data).map(([value, label]) => {
                  return {
                    value,
                    label: label["label"],
                  };
                })}
                default_value={default_formule}
                ClassNames="custom-select-arrow-3 border-1-5 border-accent font-semibold"
              />
              {formule_value in pensions_data && (
                <span className="text-fs-13 main-color block mt-2.5 leading-lh-21">
                  {pensions_data[formule_value]["description"]}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Repartition);
