import React from "react";
import ValidateButton from "../components/ValidateButton";
import ExcursionGrouped from "../components/ExcursionGrouped";
import warning from "../img/icons/warning.svg";
import CancelButton from "../components/CancelButton";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

const ExcursionPopUpMode = ({ datas, block, index }) => {
  let excurstion_codes = [];
  let validateStrings = [];
  (Object.keys(datas) || []).forEach((dest) => {
    excurstion_codes = [
      ...excurstion_codes,
      ...Object.keys(datas[dest]["data"]),
    ];
    validateStrings.push(`excursion.${dest}.code`);
  });
  const {
    formState: { errors },
  } = useFormContext();
  return (
    <div className="modal">
      <div className="modal-content max-w-1172">
        <div className="flex justify-between pb-5">
          <h3 className="gold-color text-fs-21 leading-lh-37 merriweather font-bold ">
            Excursions
          </h3>
        </div>

        <div className="flex flex-col main-color [&>div]:mb-2.5 [&>div:last-child]:mb-0">
          {Object.keys(datas).map((dest) => {
            if (Object.keys(datas[dest]["data"]).length > 0)
              return (
                <ExcursionGrouped
                  datas={datas[dest]}
                  key={dest}
                  dest={dest}
                  block={block}
                  label={block}
                  excursion_datas={excurstion_codes}
                />
              );
            return null; // Add this line to resolve the warning
          })}
        </div>

        <div className="flex flex-col mt-value-30">
          {Object.keys(errors).length > 0 && (
            <div className="flex text-fs-14 px-5 py-3.5 bg-gold rounded-md items-center mb-value-30 bigdesktop:w-1132 bigdesktop:-ml-235">
              <img className="self-start mr-5" src={warning} alt="" />
              <div>
                {(validateStrings || []).map((name, key) => {
                  return (
                    <ErrorMessage
                      key={key}
                      errors={errors}
                      name={name}
                      render={({ message }) => (
                        <span className="text-white block font-semibold">
                          {message}
                        </span>
                      )}
                    />
                  );
                })}
              </div>
            </div>
          )}
          <ValidateButton
            classes={
              "p-16-35 leading-lh-15 block mx-auto main-bg-color text-white br-26 text-fs-14 w-value-226 disabled:bg-disabled-button"
            }
            button={{
              label: "Valider",
              type: "SELECT_EXCURSION",
            }}
            validate={true}
            block={block}
            validateStrings={validateStrings}
          />
          <CancelButton block={block} index={index} />
        </div>
      </div>
    </div>
  );
};

export default ExcursionPopUpMode;
