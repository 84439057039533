import React from "react";
import { RemoveTrailingZero } from "../helper";

const AssuranceRow = ({
  title,
  b2b_description,
  price,
  prestation_code,
  onChange,
  radioValue,
}) => {
  return (
    <div className="flex flex-col main-color [&>div]:mb-2.5  [&>div:last-child]:mb-0">
      <div className="flex desktop:items-center p-5 border rounded-md border-gray-200 tablet:flex-col">
        <div className="flex flex-col flex-1">
          <span className="font-bold mb-2.5 lowercase capitalize-first">
            {title}
          </span>
          <span
            className="text-fs-14 leading-lh-18"
            dangerouslySetInnerHTML={{ __html: b2b_description }}
          />
        </div>
        <div className="flex items-center tablet:mt-5 tablet:justify-center ml-value-60">
          {price !== undefined ? (
            <div className="flex flex-col">
              <span className="text-fs-12 font-semibold">À partir de</span>
              <div className="flex items-center">
                <span className="text-fs-21 leading-lh-32 font-bold">
                  {RemoveTrailingZero(price)} €
                </span>
                <span className="text-fs-12 pl-5px leading-lh-15">/ Pers.</span>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="customradiobutton relative cursor-pointer ml-5">
            <input
              name="assurance"
              onChange={onChange}
              type="radio"
              value={prestation_code}
              id={prestation_code}
              checked={prestation_code === radioValue}
            />
            <label className="checkmark" htmlFor={prestation_code}>
              Sélectionner
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssuranceRow;
