import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { transformComponent } from "./Components";
import { isLoading, isSuccess, isError } from "../selectors/products";
import {
  isProductSelected,
  getAvailableDays,
  getCurrentDest,
  getNumIsland,
  Islands,
} from "../selectors/userSelection";
import { isPossibleCombination } from "../selectors/destination";
import { useOnceCall } from "../hooks";
import { getProductsEnhanced } from "../actions";
import { useFormContext } from "react-hook-form";
import HotelsWarning from "../components/HotelsWarning";
import ErreurModal from "../components/ErreurModal";
import Loading from "../components/Loading";

const HotelsWrapper = ({ block, index }) => {
  const { getValues } = useFormContext();
  const { children, mode, dest, island } = block;
  const current_dest = useSelector(getCurrentDest);
  const num_island = useSelector(getNumIsland);
  const island_index = num_island - 1;
  const islands = useSelector(Islands);
  const current_island = island_index in islands ? islands[island_index] : "";
  const current =
    island === undefined ? current_dest === dest : current_island === island;
  const hotel_exist = useSelector(isProductSelected(index));
  const available_day = useSelector(getAvailableDays);
  const is_possible_combination = useSelector(isPossibleCombination(dest));
  const dispatch = useDispatch();
  const loading = useSelector(isLoading(dest));
  const success = useSelector(isSuccess(dest));
  const error = useSelector(isError(dest));

  useOnceCall(() => {
    dispatch(getProductsEnhanced({ ...getValues(), products_desti: dest }));
  });

  return (
    <>
      <div>
        {mode === "recap" && (
          <>
            {(children || []).map((block, index) =>
              transformComponent(block, index)
            )}
          </>
        )}
        {mode === "button" &&
          (children || []).map((block, index) =>
            transformComponent(block, index)
          )}
        {mode === "popup" &&
          success &&
          (children || []).map((block, index) =>
            transformComponent(block, index)
          )}
      </div>
      {mode === "popup" && loading && <Loading />}
      {mode === "popup" && error && (
        <ErreurModal
          message={"Erreur de la recherche des produits de la destination."}
        />
      )}
      {current &&
        hotel_exist &&
        (available_day > 0 ||
          (available_day === 0 &&
            is_possible_combination === false &&
            island !== undefined)) && (
          <HotelsWarning
            block={block}
            index={index}
            available_day={available_day}
          />
        )}
    </>
  );
};

export default HotelsWrapper;
