import React from "react";
import { useSelector } from "react-redux";
import {
  getCurrentDest,
  canAddProducts,
  getNextDest,
} from "../selectors/userSelection";
import {
  getDestination,
  getIslands,
  isSuccess,
  possibleInteriorFlights,
} from "../selectors/destination";
import CallToActionButton from "../components/CallToActionButton";
import warning from "../img/icons/warning.svg";

const HotelsWarning = ({ block, available_day, index }) => {
  const { dest, island } = block;
  const islands = useSelector(getIslands(dest));
  const { hotels_or_products_max } = useSelector(getDestination(dest));

  const can_add_products = useSelector(
    canAddProducts(index)(hotels_or_products_max)
  );

  const next_dest = useSelector(getNextDest);
  const current_dest = useSelector(getCurrentDest);
  const success = useSelector(isSuccess(next_dest));
  const { possible, messages } = useSelector(
    possibleInteriorFlights(island !== undefined)(dest)
  );
  const { label_destination } = useSelector(getDestination(next_dest));
  const next_desti_label =
    next_dest !== undefined && next_dest !== "none"
      ? `Continuer sur ${label_destination}`
      : "";

  const next_desti_button = {
    type: "CALL_TO_ACTION_NEXT_DEST_HOTELS",
    label: next_desti_label,
  };

  return (
    <div>
      {available_day > 0 && possible === true && (
        <div className="flex px-5 py-3.5 bg-gold rounded-t-md items-center">
          <img className="self-start mr-5" src={warning} alt="warning" />
          <span className="text-white block font-semibold">
            Il manque {available_day} nuit(s) par rapport aux dates de votre
            voyage, que souhaitez vous faire ?
          </span>
        </div>
      )}
      {available_day === 0 && possible === true && (
        <div className="flex px-5 py-3.5 bg-gold rounded-t-md items-center">
          <img className="self-start mr-5" src={warning} alt="warning" />
          <span className="text-white block font-semibold">
            Cette combinaison d'île n'est pas permise, veuillez modifier le
            nombre de nuits.
          </span>
        </div>
      )}
      {possible === false && (
        <div className="flex px-5 py-3.5 bg-gold rounded-t-md items-center">
          <img className="self-start mr-5" src={warning} alt="warning" />
          {messages.map((message, index) => (
            <span className="text-white block font-semibold" key={index}>
              {message}
            </span>
          ))}
        </div>
      )}
      {available_day > 0 && possible === true && (
        <div className="flex flex-col bg-white rounded-b-md">
          <div className="flex flex-wrap px-5 pt-5 [&>button]:mr-5 [&>button:last-child]:mr-0">
            {/*     <CallToActionButton
            button={{
              type: "CALL_TO_ACTION_EDIT_NIGHT_NUMBER",
              label: "Modifier le nombre de nuit(s) de hôtel choisi",
            }}
            block={block}
            index={index}
          /> */}

            <CallToActionButton
              button={{
                type: "CALL_TO_ACTION_ADD_ROOM_CATEGORY",
                label: "Ajouter une autre catégorie de chambre",
              }}
              block={block}
              index={index}
            />

            {island === undefined && can_add_products && (
              <>
                <CallToActionButton
                  button={{
                    type: "CALL_TO_ACTION_SAME_DEST_HOTELS",
                    label: `Ajouter un autre hôtel`,
                  }}
                  block={block}
                  index={index}
                />
              </>
            )}
            {next_dest !== undefined &&
              next_dest !== "none" &&
              success &&
              current_dest !== next_dest && (
                <CallToActionButton
                  button={next_desti_button}
                  block={block}
                  index={index}
                />
              )}
            {island !== undefined &&
              islands.length !== 0 &&
              available_day > 0 && (
                <>
                  <CallToActionButton
                    button={{
                      type: "CALL_TO_ACTION_NEXT_ISLAND",
                      label: "Ajouter une île",
                    }}
                    block={block}
                    index={index}
                  />
                </>
              )}
          </div>
        </div>
      )}
    </div>
  );
};

export default HotelsWarning;
