import React from "react";
import { RemoveTrailingZero } from "../helper";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

const ExcursionRecapMode = ({ excursion, validity_day }) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = excursion.teaser;
  const textContent = tempDiv.textContent;
  return (
    <div className="flex desktop:items-center p-2.5 bg-white main-color rounded-md tablet:flex-col">
      <div className="flex flex-1">
        <img
          src={excursion.image}
          alt="Excursion"
          className="w-133 object-cover rounded-md mr-5 h-90"
        />
        <div className="flex flex-col text-fs-14 flex-1 mr-5">
          <div className="flex flex-col">
            <span className="font-bold">{excursion.title}</span>
            {excursion.teaser !== null ? (
              <span className="w-value-522 mb-5">
                <p className="paragh-elipsis">{textContent}</p>
              </span>
            ) : (
              <></>
            )}
          </div>
          {validity_day !== undefined &&
          validity_day !== "" &&
          validity_day !== "all" ? (
            <div className="flex items-center mt-auto">
              <div className="font-bold mr-5">Jours de validité</div>
              <span className="h-value-40 rounded-md border-b-gray-200 border flex items-center px-3 mr-2 capitalize">
                {format(new Date(validity_day), "eeee dd MMM yyyy", {
                  locale: fr,
                })}
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="flex items-center tablet:mt-5 tablet:justify-center">
        <div className="flex flex-col">
          <div className="flex items-center">
            <span className="text-fs-21 leading-lh-32 font-bold">
              {excursion.price !== null
                ? RemoveTrailingZero(excursion.price.price)
                : "price"}{" "}
              €
            </span>{" "}
            /
            {excursion.price.type !== null ? (
              <span className="text-fs-12 pl-5px leading-lh-15">
                {excursion.price.type === "Par personne" ? "Pers." : "Chb"}
              </span>
            ) : (
              <></>
            )}
          </div>
          {excursion.price.board !== null ? (
            <span className="text-fs-13 w-value-140">
              {excursion.price.board}
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExcursionRecapMode;
