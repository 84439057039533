import { getContentBykey } from "./rooms";
import { getCountHotels } from "../selectors/userSelection";
import { getDestination } from "../selectors/destination";
import { getDatas } from "../selectors/userSelection";
import { getLastKey } from "../helper";
import { createSelector } from "reselect";
const { isAfter, isEqual, parse, isBefore } = require("date-fns");

const getAllProducts = (state) => state.productsReducer.products;

const isSuccess = (dest) => (state) => {
  const success_obj = state.productsReducer.isSuccess;
  if (dest in success_obj) return success_obj[dest];
  return false;
};

const isLoading = (dest) => (state) => {
  const loading_obj = state.productsReducer.isLoading;
  if (dest in loading_obj) return loading_obj[dest];
  return false;
};

const isError = (dest) => (state) => {
  const error_obj = state.productsReducer.isError;
  if (dest in error_obj) return error_obj[dest];
  return false;
};

const getProductsState = (state, dest, island) => {
  let products = getAllProducts(state);
  if (products.hasOwnProperty(dest)) {
    if (island === undefined) {
      return products[dest]["products"];
    } else {
      if (island in products[dest])
        return state.productsReducer.products[dest][island]["products"];
    }
    return {};
  }

  return products;
};

const getProductsStateWithSelectors = (dest, island) => (state) => {
  let products = getAllProducts(state);
  if (products.hasOwnProperty(dest)) {
    if (island === undefined) {
      return products[dest]["products"];
    } else {
      if (island in products[dest])
        return state.productsReducer.products[dest][island]["products"];
    }
    return {};
  }
  return products;
};

const getProductsFiltered = (dest, island, children) => {
  return createSelector(
    [getProductsStateWithSelectors(dest, island, children)],
    (products) => {
      let filtered_products = {};
      filtered_products =
        children === 0
          ? { ...products }
          : filterHotelsWithoutAdultsOnly(products);
      return filtered_products;
    }
  );
};

function filterHotelsWithoutAdultsOnly(hotels) {
  const filteredHotels = Object.keys(hotels)
    .filter((hotelCode) => {
      const hotel = hotels[hotelCode];
      return !Object.values(hotel.themes).includes("Adults Only");
    })
    .reduce((result, hotelCode) => {
      result[hotelCode] = hotels[hotelCode];
      return result;
    }, {});

  return filteredHotels;
}

const getDataFormattedRroduct = (block) => {
  return createSelector([getDatas], (dataState) => {
    const { num, index_parent } = block;
    let product = {};
    if (
      "hotels" in dataState &&
      index_parent in dataState["hotels"] &&
      num in dataState["hotels"][index_parent]
    )
      product = dataState["hotels"][index_parent][num];
    return product;
  });
};

const destProductsExist = (products, dest) => {
  return products.hasOwnProperty(dest);
};

const ProductsExist = (dest) => (state) => {
  const products = getAllProducts(state);
  return destProductsExist(products, dest);
};

const GetProductsInfo = (dest) => (island) => (code) => (state) => {
  const products = getProductsState(state, dest, island);
  return code in products ? products[code] : {};
};

const GetLastHotelCodeInDestination = (state, block) => {
  const hotels = getDatas(state)["hotels"];
  const { index_parent } = block;
  const last_key = getLastKey(hotels[index_parent]);
  return hotels[index_parent][last_key]["code"];
};

const GetLastHotelIndex = (block) => (state) => {
  const hotels = getDatas(state)["hotels"];
  const { index_parent } = block;
  return getLastKey(hotels[index_parent]);
};

const getDataTransfert = (state, block) => {
  let { dest, island, index_parent } = block;
  let data_transfert = { transfert: {}, location: {} };
  let {
    bl_transfers,
    multiproduct_associated_transfer,
    bl_linked_transfers,
    transfers_contents,
    car_rental_contents,
  } = getDestination(dest)(state);

  // transferts disponible sur desti.
  if (bl_transfers === "1") {
    // selection multi produits?.
    let transfert_content = {};
    const count_hotel = getCountHotels(index_parent)(state);
    // if multi produits.
    if (
      (count_hotel > 1 && multiproduct_associated_transfer === "1") ||
      count_hotel === 1
    ) {
      if (bl_linked_transfers === "0") {
        data_transfert["transfert"] = { ...transfers_contents };
      } else {
        const code_hotel = GetLastHotelCodeInDestination(state, block);
        let { transfers_codes } =
          GetProductsInfo(dest)(island)(code_hotel)(state);
        (transfers_codes || []).forEach((transfer) => {
          let code = transfer["code_trf"];
          if (code in transfers_contents) {
            transfert_content[code] = { ...transfers_contents[code] };
          }
        });
        data_transfert["transfert"] = {
          ...transfert_content,
        };
      }
    }
  }
  data_transfert["location"] = { ...car_rental_contents };
  return data_transfert;
};

const getAllRoomInfo = (DataRroductState, roomsState, pensions) => {
  let rooms = { ...DataRroductState.rooms };
  Object.keys(rooms).forEach((i) => {
    const code_room = DataRroductState["rooms"][i]["code_room"];
    const code_hotel = DataRroductState["code"];
    const rooms_hotel = roomsState[code_hotel];
    const room_content = getContentBykey(code_room, rooms_hotel.content);
    const room_fares = roomsState[code_hotel]["rooms"][code_room];
    const formule_code = DataRroductState["rooms"][i]["formule"];
    let formule =
      pensions && formule_code in pensions
        ? pensions[formule_code]
        : formule_code;
    rooms[i] = {
      ...rooms[i],
      ...room_content,
      ...room_fares,
      formule,
    };
  });
  return rooms;
};

const getFiltersOptions = (hotelsObject) => {
  let uniqueThemesArray = [
    {
      value: "all",
      label: "Par thème",
    },
  ];

  let uniqueCategoriesArray = [
    {
      value: "all",
      label: "Par catégorie",
    },
  ];

  let uniqueCityArray = [];

  let uniqueRegionArray = [
    {
      value: "all",
      label: "Par côte",
    },
  ];

  for (const hotelCode in hotelsObject) {
    const hotel = hotelsObject[hotelCode];
    if (hotel.themes) {
      for (const themeId in hotel.themes) {
        const themeLabel = hotel.themes[themeId];
        const themeObject = {
          value: themeId,
          label: themeLabel,
        };

        // Check if the theme is already in the array
        const existingTheme = uniqueThemesArray.find(
          (existingTheme) => existingTheme.value === themeObject.value
        );

        // Add the theme to the array if it's not already present
        if (!existingTheme) {
          uniqueThemesArray.push(themeObject);
        }
      }
    }

    if (hotel.regions) {
      for (const regionId in hotel.regions) {
        const regionLabel = hotel.regions[regionId];
        const regionObject = {
          value: regionId,
          label: regionLabel,
        };

        // Check if the theme is already in the array
        const existingRegion = uniqueRegionArray.find(
          (existingRegion) => existingRegion.value === regionObject.value
        );

        // Add the theme to the array if it's not already present
        if (!existingRegion) {
          uniqueRegionArray.push(regionObject);
        }
      }
    }

    if (hotel.city_island) {
      for (const cityId in hotel.city_island) {
        const city_islandLabel = hotel.city_island[cityId];
        const city_islandObject = {
          value: cityId,
          label: city_islandLabel,
        };

        // Check if the theme is already in the array
        const existingCity = uniqueCityArray.find(
          (existingCity) => existingCity.value === city_islandObject.value
        );

        // Add the theme to the array if it's not already present
        if (!existingCity) {
          uniqueCityArray.push(city_islandObject);
        }
      }
    }

    if (hotel.category) {
      let categoryObject = {};
      if (hotel.category !== "0") {
        categoryObject = {
          value: hotel.category,
          label: `${hotel.category} étoiles`,
        };
      } else {
        categoryObject = {
          value: hotel.category,
          label: `Petite hôtellerie`,
        };
      }

      // Check if the category is already in the array
      const existingCategory = uniqueCategoriesArray.find(
        (existingCategory) => existingCategory.value === categoryObject.value
      );

      // Add the category to the array if it's not already present
      if (!existingCategory) {
        uniqueCategoriesArray.push(categoryObject);
      }
    }
  }
  uniqueCityArray.sort((a, b) => a.label.localeCompare(b.label));
  uniqueCityArray.unshift({
    value: "all",
    label: "Par ville",
  });
  return {
    uniqueCityArray,
    uniqueCategoriesArray,
    uniqueThemesArray,
    uniqueRegionArray,
  };
};

function filterHotelData(
  products,
  category_filter,
  theme_filter,
  cities_filter,
  search_filter,
  regions_filter
) {
  let result = Object.keys(products);
  if (
    category_filter !== "all" &&
    category_filter !== undefined &&
    category_filter !== "0"
  ) {
    result = result.filter(
      (item) => products[item].category === category_filter
    );
  }

  if (
    category_filter !== "all" &&
    category_filter !== undefined &&
    category_filter === "0"
  ) {
    result = result.filter(
      (item) =>
        products[item].bundle === "hotel_step" ||
        products[item].category === "0"
    );
  }

  if (theme_filter !== "all" && theme_filter !== undefined) {
    result = result.filter((item) =>
      Object.keys(products[item].themes).includes(theme_filter)
    );
  }
  if (cities_filter !== "all" && cities_filter !== undefined) {
    result = result.filter((item) =>
      Object.keys(products[item].city_island).includes(cities_filter)
    );
  }
  if (regions_filter !== "all" && regions_filter !== undefined) {
    result = result.filter((item) =>
      Object.keys(products[item].regions).includes(regions_filter)
    );
  }
  if (search_filter !== undefined && search_filter.length > 0) {
    result = result.filter((item) =>
      products[item]["label"].toLowerCase().includes(search_filter)
    );
  }
  return result;
}

const getValidOffers = (offers) => {
  return createSelector([getDatas], (dataState) => {
    if (offers === undefined) return {};
    const {
      staydates: { startDate, endDate },
    } = dataState;
    const valid_offers = Object.fromEntries(
      Object.entries(offers).filter(([_, offer]) => {
        let {
          date: { start, end },
        } = offer;
        if (start === null) return true;
        const date_start_offer = parse(start, "yyyy-MM-dd", new Date());
        const date_end_offer = parse(end, "yyyy-MM-dd", new Date());
        const date_start_staydates = parse(startDate, "yyyy-MM-dd", new Date());
        const date_end_staydates = parse(endDate, "yyyy-MM-dd", new Date());
        if (
          (isAfter(date_start_staydates, date_start_offer) ||
            isEqual(date_start_staydates, date_start_offer)) &&
          (isBefore(date_end_staydates, date_end_offer) ||
            isEqual(date_end_staydates, date_end_offer))
        )
          return true;
        return false;
      })
    );

    return valid_offers;
  });
};

const GetProductsType = (block) => (state) => {
  const { dest, island } = block;
  const properties = getProductsState(state, dest, island);
  const types = new Set();
  for (const key in properties) {
    if (properties.hasOwnProperty(key) && properties[key].type !== undefined) {
      types.add(properties[key].type);
    }
  }
  let array_types = Array.from(types);
  const sortedTypes = array_types.sort((a, b) => b.localeCompare(a));
  const typesString = sortedTypes.join(" / ");
  return typesString.length > 0 ? typesString : "Hôtel";
};

export {
  isLoading,
  getProductsState,
  destProductsExist,
  GetProductsInfo,
  getDataFormattedRroduct,
  getAllRoomInfo,
  getDataTransfert,
  ProductsExist,
  isSuccess,
  isError,
  getProductsFiltered,
  getFiltersOptions,
  filterHotelData,
  GetLastHotelIndex,
  getValidOffers,
  GetProductsType,
};
