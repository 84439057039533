import React from "react";
import RadioButton from "./RadioButton";

const Island = ({ data, block, code }) => {
  let { image, title, description } = data;

  let { num_island } = block;
  return (
    <div className="border border-gray-200 rounded-md flex items-center justify-between py-2 px-4 mb-2.5 w-full">
      <div className="flex items-center">
        {image && (
          <img
            className="h-90 w-90 object-cover"
            src={image["url"]}
            alt={image["alt"]}
          />
        )}

        <div className="ml-5 flex flex-col">
          <div className="mb-2.5">
            {title && <span className="font-bold">{title} </span>}
            {title === undefined && <span className="font-bold">{code} </span>}
          </div>
          {description !== undefined && (
            <div
              className="text-fs-14 main-color leading-lh-24 mb-2.5 text-justify"
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
          )}
        </div>
      </div>
      <RadioButton
        id={`${code}`}
        value={`${code}`}
        name={`island_${num_island}`}
      />
    </div>
  );
};

export default Island;
