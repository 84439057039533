import React from "react";
import { useSelector } from "react-redux";
import {
  GetProductsInfo,
  getDataFormattedRroduct,
  getAllRoomInfo,
  GetLastHotelIndex,
} from "../selectors/products";
import RoomRecap from "./RoomRecap";
import Calender from "../img/icons/calendar.png";
import formatDate from "../validation/formateDate";
import EditButton from "../components/EditButton";

const HotelRecapMode = ({ block, index, name }) => {
  const { dest, num, island } = block;
  const roomsState = useSelector((state) => state.roomsReducer.rooms);
  const DataProduct = useSelector(getDataFormattedRroduct(block));
  const hotel_info = useSelector(
    GetProductsInfo(dest)(island)(DataProduct["code"])
  );

  const RoomsData = getAllRoomInfo(
    DataProduct,
    roomsState,
    hotel_info["pensions"]
  );
  const hotelStars = Array.from(
    { length: hotel_info["category"] },
    (_, index) => index
  );
  const last = useSelector(GetLastHotelIndex(block));
  return (
    <div
      className={`${
        parseInt(last) === num
          ? "bg-white px-5 pb-5 rounded-b-md"
          : "bg-white px-5"
      }`}
    >
      <div className="flex bg-white justify-between items-center rounded-t-md py-5 ">
        {index === 0 && (
          <h3 className="gold-color text-fs-21 leading-lh-27 merriweather font-bold">
            {name}
          </h3>
        )}
        <span></span>
        <EditButton block={block} index={index} />
      </div>
      <div className="rounded-md bg-grey-pagination px-5 py-4 items-center main-color flex justify-between mb-2.5 tablet:flex-col ">
        <span className="font-bold block tablet:mb-5 text-fs-21 customtypo">
          {hotel_info["label"]}{" "}
          {hotelStars.map((_, key) => {
            return "*";
          })}{" "}
          {hotel_info["category_complement"]}
        </span>
        <div className="flex items-center">
          <span className="merriweather font-bold">
            {DataProduct[`nbr_nights`]} nuit(s)
          </span>
          <div className="flex items-center">
            <div className="bg-white rounded-md flex items-center h-value-30 cursor-pointer px-5 ml-5">
              <img className="w-15 h-15" src={Calender} alt="" />
              <span className="text-fs-14 ml-2.5">
                {formatDate(DataProduct[`start_date`])}
              </span>
            </div>
            <div className="bg-white rounded-md flex items-center h-value-30 cursor-pointer px-5 ml-5">
              <img className="w-15 h-15" src={Calender} alt="" />
              <span className="text-fs-14 ml-2.5">
                {formatDate(DataProduct[`end_date`])}
              </span>
            </div>
          </div>
        </div>
      </div>
      {Object.keys(RoomsData).map((key) => {
        return <RoomRecap key={key} data={RoomsData[key]} />;
      })}

      {parseInt(last) !== num && num > 0 && (
        <div className="h-value-40 bg-white w-full flex justify-center items-center px-5">
          <div className="bg-gray-200 px-5 h-px w-full"></div>
        </div>
      )}
    </div>
  );
};

export default HotelRecapMode;
