import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../css/style.css";
import arrowRight from "../img/heroslideshow/arrow-right-white.png";
import arrowLeft from "../img/heroslideshow/arrow-left-white.png";

function HotelGallery({ ImagesArray }) {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: `url(${arrowRight}) no-repeat center`,
          backgroundSize: "60px",
        }}
        onClick={onClick}
      />
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: `url(${arrowLeft}) no-repeat center`,
          backgroundSize: "60px",
        }}
        onClick={onClick}
      />
    );
  }

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    infinite: true,
    fade: false,
    dots: true,
    arrows: true,
    cssEase: "ease-in-out",
    lazyLoad: "progressive",
    asNavFor: ".slider-nav",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const settingsThumbs = {
    slidesToShow: ImagesArray.length === 2 ? 2 : ImagesArray.length,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: "10px",
    lazyLoad: "progressive",
  };

  return (
    <div className="slider-wrapper hotel-popup-slider">
      <div className="main-slider">
        <Slider
          {...settingsMain}
          asNavFor={nav2}
          ref={(slider) => setSlider1(slider)}
        >
          {ImagesArray.map((slide, index) => (
            <div className="slick-slide" key={index}>
              <img
                className="slick-slide-image"
                src={slide.url}
                alt={slide.alt}
              />
            </div>
          ))}
        </Slider>
      </div>
      {ImagesArray.length > 1 ? (
        <div className="thumbnail-slider-wrap">
          <Slider
            {...settingsThumbs}
            asNavFor={nav1}
            ref={(slider) => setSlider2(slider)}
          >
            {ImagesArray.map((slide, key) => (
              <div className="slick-slide cursor-pointer" key={key}>
                <img
                  className="slick-slide-image"
                  src={slide.url}
                  alt={slide.alt}
                />
              </div>
            ))}
          </Slider>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default HotelGallery;
