const parseHotelDatas = (datas, block, extra_data) => {
  let { pension, rooms_fares } = extra_data;
  let rooms = {};
  const { num, dest, index_parent } = block;

  const code = datas[`code_hotel_${index_parent}_${num}`];
  const nbr_nights = datas[index_parent][num][code]["nbr_nights"];
  const nbr_rooms = datas[index_parent][num][code]["nbr_rooms"];
  const roomArray = Array.from({ length: nbr_rooms }, (_, index) => index);
  roomArray.forEach((_, key) => {
    const code_room = datas[index_parent][num][code]["room"][key]["code"];
    const status = rooms_fares[code_room]["availability"];
    let ages = [];
    let adult_number = 0;
    let children_number = 0;

    [...Array(datas["adults"])].forEach((_, i) => {
      if (
        datas[index_parent][num][code]["room"][key]["repartition"][
          "adults"
        ].includes(`adult_${i + 1}`)
      )
        adult_number++;
    });
    [...Array(datas["children"])].forEach((_, i) => {
      if (
        datas[index_parent][num][code]["room"][key]["repartition"][
          "children"
        ].includes(`children_${i + 1}`)
      ) {
        children_number++;
        ages.push(datas[`age_${i + 1}`]);
      }
    });
    const formule = datas[index_parent][num][code]["room"][key]["formule"];
    const lib =
      typeof pension === "object" &&
      formule in pension &&
      "label" in pension[formule]
        ? pension[formule]["label"]
        : formule;
    const formule_enhanced = { code: formule, lib };
    rooms[key] = {
      code_room,
      adult_number,
      children_number,
      ages,
      formule,
      formule_enhanced,
      status,
    };
  });

  let hotel = { code, dest, nbr_rooms, nbr_nights, rooms };
  return hotel;
};

const excursionHasValues = (values) => {
  const excursion = values.excursion;
  let found = false;
  Object.keys(excursion).forEach((dest) => {
    if (
      (Array.isArray(excursion[dest]["code"]) &&
        excursion[dest]["code"].length > 0) ||
      (typeof excursion[dest]["code"] === "string" &&
        excursion[dest]["code"].length > 0)
    ) {
      found = true;
    }
  });
  return found;
};
export { parseHotelDatas, excursionHasValues };
