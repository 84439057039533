import React from "react";
import Datepicker from "react-tailwindcss-datepicker";
import { useController, useFormContext } from "react-hook-form";
import { format } from "date-fns";

const DateInput = ({
  name,
  id,
  asSingle = true,
  useRange = false,
  placeholder = false,
  startDate = "",
  validateDate,
}) => {
  const { clearErrors } = useFormContext();
  const controllerProps = {
    name: id,
    rules: {
      required: {
        value: true,
        message: `Le champs ${name.toLowerCase()} est requis.`,
      },
    },
  };

  if (startDate) {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    const minimumEndDate = new Date(startDate);
    minimumEndDate.setDate(minimumEndDate.getDate() + 9);

    controllerProps["defaultValue"] = {
      startDate: null,
      endDate: null,
    };
  }

  if (validateDate) {
    controllerProps["rules"]["validate"] = validateDate;
  }

  const { field } = useController(controllerProps);

  const disablePastDates = (date) => {
    const currentDate = new Date().setHours(0, 0, 0, 0);
    const selectedDate = new Date(date).setHours(0, 0, 0, 0);
    if (startDate && selectedDate.getTime() === new Date(startDate).getTime()) {
      return true;
    }
    return selectedDate < currentDate;
  };

  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 7);
  const tomorrowDate = currentDate.toISOString().split("T")[0];
  const minimumEndDate = new Date(startDate);
  minimumEndDate.setDate(minimumEndDate.getDate() + 3);
  const today = new Date();
  const firstDateOfMonth = format(today, "yyyy-MM-01");
  return (
    <>
      <Datepicker
        startWeekOn="mon"
        placeholder={placeholder}
        i18n={"fr"}
        primaryColor={"blue"}
        value={field.value}
        useRange={useRange}
        onChange={(e) => {
          // send data to react hook form
          clearErrors();
          field.onChange(e);
        }}
        asSingle={asSingle}
        displayFormat="DD/MM/YYYY"
        inputName={name}
        inputId={field.name}
        startFrom={firstDateOfMonth}
        minDate={id !== "weddingdate" ? tomorrowDate : null}
        maxDate={new Date().setDate(currentDate.getDate() + 365)}
        beforeRenderDay={(date) => ({
          disabled: disablePastDates(date),
        })}
      />
    </>
  );
};

export default DateInput;
