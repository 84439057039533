import { put } from "redux-saga/effects";
import { fetchWithTimeout } from "../helper";

function* fetchDestination(action) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const headers = new Headers();
  const { dest, api_key } = action.payload;
  if (api_key.length > 0) headers.append("api-key", api_key);
  let request = `${API_ENDPOINT}/api/v1/destination/`;
  request += dest;

  try {
    const response = yield fetchWithTimeout(request, { headers });
    if (response.status === 200) {
      const json = yield response.json();
      yield put({ type: "destination/putDestination", json, dest });
    } else {
      throw response;
    }
  } catch (e) {
    yield put({
      type: "destination/errorApiDestination",
      message: e.message,
      dest,
    });
  }
}

export { fetchDestination };
