import React from "react";
import { useSelector } from "react-redux";
import { useWatch, useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
  getFirstDestination,
  isSuccess,
  isLoading,
  isError,
} from "../selectors/destination";
import DateInput from "../components/DateInput";
import Select from "../components/Select";
import InputNumber from "../components/InputNumber";
import ValidateButton from "../components/ValidateButton";
import CancelButton from "../components/CancelButton";
import TravelersRecapMode from "../components/TravelersRecapMode";
import Loading from "../components/Loading";
import ErreurModal from "../components/ErreurModal";
import warning from "../img/icons/warning.svg";

function Travelers({ block, index }) {
  const { mode } = block;
  const {
    register,
    unregister,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const validateStrings = [`weddingdate`];
  const { dest } = getValues();
  const { label_destination, combinaison_destinations } =
    useSelector(getFirstDestination);

  const success = useSelector(isSuccess(dest));
  const loading = useSelector(isLoading(dest));
  const error = useSelector(isError(dest));

  const multi_dest_options = Object.keys(combinaison_destinations || []).map(
    (key) => {
      return {
        label: `${label_destination} + ${combinaison_destinations[key]}`,
        value: key,
      };
    }
  );

  if (multi_dest_options.length !== 0) {
    multi_dest_options.unshift({ label: "Choisir une option", value: "none" });
  }

  const profile_options = [
    { label: "Sélectionner un profil voyageur", value: "none" },
    { label: "Voyage de noces", value: "honeymoon" },
    {
      label: "Anniversaire de mariage",
      value: "wedding_anniversary",
    },
  ];

  const profile = useWatch({
    name: `profile`,
  });

  const children = useWatch({
    name: `children`,
  });

  React.useEffect(() => {
    profile === "wedding_anniversary"
      ? register(`weddingdate`)
      : unregister(`weddingdate`);

    if (profile === "wedding_anniversary" || profile === "honeymoon") {
      setValue(`children`, 0);
      setValue(`adults`, 2);
    }

    [...Array(4)].forEach((x, i) => {
      if (i + 1 > children) {
        unregister(`age_${i + 1}`);
      }
    });
  }, [register, unregister, profile, children, setValue]);
  return (
    <>
      {mode === "recap" && <TravelersRecapMode block={block} index={index} />}
      {mode === "popup" && success && (
        <div className="modal">
          <div className="modal-content max-w-350 tablet:!ml-auto tablet:!mr-auto">
            <h3 className="gold-color text-fs-21 leading-lh-37 merriweather font-bold pb-5 border-b border-b-gray-200">
              Voyageurs
            </h3>
            <div className="flex flex-col main-color text-fs-14 py-5 border-b border-b-gray-200">
              <span className="leading-lh-18 text-fs-14 font-semibold mb-2.5">
                Profil voyageur
              </span>
              <Select
                name="PROFIL VOYAGEUR"
                id={`profile`}
                options={profile_options}
                default_value="none"
              />
              {profile === "wedding_anniversary" || profile === "honeymoon" ? (
                <div className="flex flex-col main-color text-fs-14 pt-5">
                  <span className="leading-lh-18 text-fs-14 font-semibold mb-2.5">
                    Date du mariage
                  </span>
                  <div className="bg-white border border-gray-200 rounded-md flex items-center h-value-40 cursor-pointer">
                    <DateInput
                      name="Date du mariage"
                      id={`weddingdate`}
                      placeholder={"JJ/MM/YYYY"}
                    />
                  </div>
                </div>
              ) : null}
            </div>

            {profile !== "wedding_anniversary" && profile !== "honeymoon" ? (
              <div
                className={`flex flex-col main-color pt-5 border-b border-b-gray-200 ${
                  multi_dest_options.length === 0 ? "mb-5" : null
                }`}
              >
                <div className="flex items-center justify-between mb-5">
                  <div className="flex flex-col">
                    <p className="text-fs-14 leading-lh-18 font-semibold">
                      Adulte(s)
                    </p>
                    <span className="text-fs-12 leading-lh-18">
                      18 ans et plus
                    </span>
                  </div>
                  <InputNumber
                    id={`adults`}
                    default_value={2}
                    min={1}
                    max={10}
                  />
                </div>

                <div className="flex items-center justify-between mb-5">
                  <div className="flex flex-col">
                    <p className="text-fs-14 leading-lh-18 font-semibold">
                      Enfant(s)
                    </p>
                    <span className="text-fs-12 leading-lh-18">
                      De 0 à 17 ans
                    </span>
                  </div>
                  <InputNumber
                    id={`children`}
                    default_value={0}
                    min={0}
                    max={4}
                  />
                </div>
                {children > 0 ? (
                  <>
                    {[...Array(parseInt(children))].map((x, i) => (
                      <div
                        className="flex items-center justify-between mb-5"
                        key={`age_${i + 1}`}
                      >
                        <div className="flex flex-col">
                          <p className="text-fs-14 leading-lh-18 font-semibold">
                            {`Âge de l’enfant ${i + 1}`}
                          </p>
                          <span className="text-fs-12 leading-lh-18">
                            Au moment du voyage
                          </span>
                        </div>
                        <div className="flex border border-gray-200 h-value-30 rounded-md">
                          <InputNumber
                            id={`age_${i + 1}`}
                            default_value={1}
                            min={0}
                            max={18}
                          />
                        </div>
                      </div>
                    ))}
                  </>
                ) : null}
              </div>
            ) : (
              <></>
            )}
            {multi_dest_options.length !== 0 ? (
              <div className="flex flex-col main-color text-fs-14 pt-5">
                <div className="flex flex-col mb-5">
                  <span className="leading-lh-18 text-fs-14 font-semibold mb-2.5">
                    Multi-destinations
                  </span>
                  <Select
                    id={`next_dest`}
                    options={multi_dest_options}
                    default_value="none"
                  />
                </div>
              </div>
            ) : null}
            {Object.keys(errors).length > 0 && "weddingdate" in errors && (
              <div className="flex text-fs-14 px-5 py-3.5 bg-gold rounded-md items-center mb-value-10 bigdesktop:w-1132 bigdesktop:-ml-235">
                <img className="self-start mr-5" src={warning} alt="" />
                <div>
                  <ErrorMessage
                    errors={errors}
                    name={`weddingdate`}
                    render={({ message }) => (
                      <span className="text-white block font-semibold">
                        {message}
                      </span>
                    )}
                  />
                </div>
              </div>
            )}
            <ValidateButton
              classes={
                "p-16-35 leading-lh-15 block mx-auto mt-value-30 main-bg-color text-white br-26 text-fs-14 w-value-226"
              }
              button={{
                label: "Valider",
                type: "SELECT_TRAVELERS",
              }}
              validateStrings={validateStrings}
            />
            <CancelButton block={block} index={index} />
          </div>
        </div>
      )}
      {mode === "popup" && (error || dest === null) && (
        <ErreurModal
          message={"Erreur lors de la recherche des données de la destination"}
        />
      )}
      {mode === "popup" && loading && <Loading />}
    </>
  );
}

export default Travelers;
