import React from "react";
import EditButton from "./EditButton";

const IslandRecapMode = ({ data, block, index }) => {
  let { image, title, description } = data;

  return (
    <div className="bg-white p-5 rounded-md">
      <div className="flex justify-between items-center mb-5">
        <h3 className="gold-color text-fs-21 leading-lh-27 merriweather font-bold">
          Ile
        </h3>
        <EditButton block={block} index={index} />
      </div>
      <div className="bg-grey-pagination rounded-md main-color pt-value-24 px-5 pb-5">
        <div className="bg-white rounded-md flex items-center justify-between py-2 pl-4 pr-150">
          <div className="flex items-center">
            {image && (
              <img
                className="h-90 w-90 object-cover"
                src={image["url"]}
                alt={image["alt"]}
              />
            )}

            <div className="ml-5 flex flex-col">
              <div className="mb-2.5">
                {title && <span className="font-bold">{title} </span>}
              </div>
              {description !== undefined && (
                <div
                  className="text-fs-14 main-color leading-lh-24 mb-2.5 text-justify"
                  dangerouslySetInnerHTML={{ __html: description }}
                ></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IslandRecapMode;
