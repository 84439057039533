import React from "react";
import FlightControlledRadioButton from "./FlightControlledRadioButton";
import { useSelector } from "react-redux";
import FlightSegment from "../components/FlightSegment";
import info_question from "../img/icons/info-question.svg";
import info_question_hover from "../img/icons/info-question-hover.svg";
import TooltipHotel from "./TooltipHotel";
import { getRealTimeApi } from "../selectors/realTime";

const FlightRow = ({
  id,
  flight_datas,
  name,
  hidden,
  field,
  setValue,
  value,
  block,
  button,
}) => {
  const { aller, retour, inter, info } = flight_datas;
  const price = useSelector(getRealTimeApi);
  const total =
    typeof price === "object" &&
    "montant" in price &&
    "total" in price["montant"]
      ? price["montant"]["total"]
      : 0;
  const total_price = aller["price"] + parseFloat(total);
  const price_formatted = total_price.toFixed(2);
  return (
    <div
      className={`mt-5 flex flex-col [&>div]:mb-5 [&>div:last-child]:mb-0 ${
        hidden ? "hidden" : ""
      }`}
    >
      <div className="flex justify-between">
        <div className="flex flex-col flex-1 rounded-md [&.selected]:shadow-selected">
          {/* VOL ALLER */}
          <FlightSegment segment={aller} info={info} type={"aller"} />
          {/* VOL INTER */}
          {inter !== undefined && (
            <FlightSegment segment={inter} info={info} type={"inter"} />
          )}
          {/* VOL RETOUR */}
          <FlightSegment segment={retour} info={info} type={"retour"} />
        </div>
        <div className="flex flex-col ml-2.5">
          <div className="flex flex-col justify-center bg-creme-light rounded-md h-value-83 min-w-110 p-3">
            {total !== 0 && (
              <>
                <div className="flex items-center justify-end mb-2.5">
                  <span className="text-fs-13 main-color font-semibold">
                    Total Voyage
                  </span>
                  <div className="relative w-3 h-3 ml-2.5 group">
                    <TooltipHotel
                      src={info_question}
                      id={`flight_${id}`}
                      content={`  Montant total calculé pour l’intégralité du voyage,
                  englobant toutes les prestations, y compris le coût du vol,
                  et ce pour l’ensemble des passagers.`}
                      active={true}
                    />
                    <img
                      className="cursor-pointer absolute left-0 top-0 opacity-100 group-hover:opacity-0"
                      src={info_question}
                      alt=""
                    />
                    <img
                      className="cursor-pointer absolute left-0 top-0 opacity-0 group-hover:opacity-100"
                      src={info_question_hover}
                      alt=""
                    />
                    <div className="bg-white main-color rounded-md absolute top-5 w-value-210 shadow-[0_5px_15px_rgba(0,0,0,0.16)] z-1 right-0 hidden p-3 group-hover:block">
                      <p className="block text-fs-13 leading-lh-18">
                        Montant total calculé pour l’intégralité du voyage,
                        englobant toutes les prestations, y compris le coût du
                        vol, et ce pour l’ensemble des passagers.
                      </p>
                    </div>
                  </div>
                </div>
                <span className="main-color text-14 whitespace-nowrap font-semibold">
                  {price_formatted} <sup className="text-fs-10">€ TTC</sup>
                </span>
              </>
            )}
          </div>

          <FlightControlledRadioButton
            id={id}
            name={name}
            field={field}
            setValue={setValue}
            value={value}
            block={block}
            button={button}
          />
        </div>
      </div>
    </div>
  );
};

export default FlightRow;
