import React from "react";
import { useFormContext } from "react-hook-form";

const RadioButton = ({ id, name }) => {
  const { register } = useFormContext();
  return (
    <div className="customradiobutton relative cursor-pointer ml-value-60">
      <input {...register(name)} id={id} value={id} type="radio" />
      <label htmlFor={id}>Sélectionner</label>
    </div>
  );
};

export default RadioButton;
