import React from "react";
import { useWatch } from "react-hook-form";
import ButtonModeComponent from "../components/ButtonModeComponent";
import ButtonCheckbox from "../components/ButtonCheckbox";
import Select from "../components/Select";
import ValidateButton from "../components/ValidateButton";
import EditButton from "../components/EditButton";

const Options = ({ block }) => {
  const { mode, disabled } = block;
  const options = useWatch({
    name: `options`,
  });

  return (
    <>
      {mode === "button" && (
        <ButtonModeComponent
          name={"Options"}
          disabled={disabled}
          block={block}
        />
      )}
      {mode === "popup" && (
        <div className="modal">
          <div className="modal-content max-w-1172">
            <div className="flex justify-between pb-5">
              <h3 className="gold-color text-fs-21 leading-lh-37 merriweather font-bold ">
                Options
              </h3>
            </div>

            <div className="text-fs-14 main-color block mb-5 tablet:text-center">
              <span className="font-bold">
                Voulez-vous des prestations optionnelles
              </span>
            </div>

            <div className="flex desktop:justify-between smalldesktop:items-center tablet:items-center tablet:justify-center smalldesktop:flex-col main-color flex-col text-fs-14">
              <div className="border border-gray-200 rounded-md flex items-center justify-between py-4 px-4 mb-2.5 w-full">
                <div className="flex flex-col">
                  <span className="font-bold mb-2.5">
                    Chambre Day Use - à l’arrivée
                  </span>
                  <span className="text-fs-13">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </span>
                  <div className="flex items-center mt-2.5">
                    <span className="font-bold mr-4">
                      Choisissez votre type d’option
                    </span>
                    <Select
                      id={`options_select`}
                      options={[
                        {
                          label: "Choisissez votre type d’option",
                          value: "none",
                        },
                        { label: "Option 1", value: "opt1" },
                        { label: "Option 2", value: "opt2" },
                      ]}
                      ClassNames="custom-select-arrow border border-gray-200 rounded-md w-350"
                      isDisabled={
                        options === undefined ||
                        options === false ||
                        (Array.isArray(options) &&
                          !options.includes("option_1"))
                      }
                    />
                  </div>
                </div>
                <ButtonCheckbox id={"option_1"} />
              </div>
              <div className="border border-gray-200 rounded-md flex items-center justify-between py-4 px-4 mb-2.5 w-full">
                <div className="flex flex-col">
                  <span className="font-bold mb-2.5">
                    FAST TRACK - Coupe fil à l’aéroport
                  </span>
                  <span className="text-fs-13">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </span>
                </div>
                <ButtonCheckbox id={"option_2"} />
              </div>
            </div>

            <div className="flex flex-col mt-2.5">
              <ValidateButton
                classes={
                  "p-16-35 leading-lh-15 block mx-auto main-bg-color text-white br-26 text-fs-14 w-value-226 disabled:bg-disabled-button"
                }
                button={{
                  label: "Valider",
                  type: "SELECT_OPTIONS",
                }}
                validate={false}
              />

              {/*  <button className="main-color mt-value-30 text-fs-14 border-b-main-color flex self-center border-b-1-5 border-accent">
                Annuler
              </button> */}
            </div>
          </div>
        </div>
      )}
      {mode === "recap" && (
        <div className="bg-white p-5 rounded-md">
          <div className="flex justify-between items-center mb-5">
            <h3 className="gold-color text-fs-21 leading-lh-27 merriweather font-bold">
              Options
            </h3>
            <EditButton block={block} />
          </div>
          <div className="p-5 bg-grey-pagination rounded-md">
            <div className="bg-white rounded-md flex items-center justify-between py-4 px-4 text-fs-14 main-color w-full">
              <div className="flex flex-col">
                <span className="font-bold mb-2.5">
                  Chambre Day Use - à l’arrivée
                </span>
                <span className="text-fs-13">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </span>
                <div className="flex items-center mt-2.5">
                  <span className="font-bold mr-4">Type de chambre</span>
                  <span className="h-value-40 rounded-md border-b-gray-200 border flex items-center px-3">
                    Chambre Supérieure jardin +200 € / par nuit
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Options;
