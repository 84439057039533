import React from "react";
import Island from "../components/Island";
import ValidateButton from "../components/ValidateButton";
import IslandRecapMode from "../components/IslandRecapMode";
import CancelButton from "../components/CancelButton";
import ButtonModeComponent from "../components/ButtonModeComponent";
import { useOnceCall } from "../hooks";
import { useSelector, useDispatch } from "react-redux";
import { useFormContext } from "react-hook-form";
import { getProductsEnhanced } from "../actions";
import { getIslandsContent } from "../selectors/destination";
import { useWatch } from "react-hook-form";
import { getFormDatas } from "../selectors/userSelection";

const Islands = ({ block, index }) => {
  const { mode, dest, islands_code, num_island, disabled } = block;
  const { getValues } = useFormContext();
  const dispatch = useDispatch();
  const content = useSelector(getIslandsContent(dest));
  const { islands } = useSelector(getFormDatas);
  const code_island = islands[num_island];
  const island_selected = useWatch({
    name: `island_${num_island}`,
  });
  useOnceCall(() => {
    dispatch(getProductsEnhanced({ ...getValues(), products_desti: dest }));
  });

  return (
    <>
      {mode === "button" && (
        <ButtonModeComponent name={"Îles"} disabled={disabled} block={block} />
      )}
      {mode === "recap" && (
        <IslandRecapMode
          data={code_island in content ? content[code_island] : {}}
          block={block}
          index={index}
        />
      )}
      {mode === "popup" && (
        <div className="modal">
          <div className="modal-content max-w-1172">
            <div className="flex justify-between pb-5">
              <h3 className="gold-color text-fs-21 leading-lh-37 merriweather font-bold ">
                Iles
              </h3>
            </div>

            <div className="flex desktop:justify-between smalldesktop:items-center tablet:items-center tablet:justify-center smalldesktop:flex-col main-color flex-col text-fs-14">
              {(islands_code || []).map((key) => {
                return (
                  <Island
                    key={key}
                    code={key}
                    data={key in content ? content[key] : {}}
                    block={block}
                  />
                );
              })}
            </div>

            <div className="flex flex-col mt-5">
              <ValidateButton
                classes={
                  "p-16-35 leading-lh-15 block mx-auto main-bg-color text-white br-26 text-fs-14 w-value-226 disabled:bg-disabled-button"
                }
                button={{
                  label: "Valider",
                  type: "SELECT_ISLAND",
                }}
                validate={false}
                disabled={
                  island_selected === undefined ||
                  !islands_code.includes(island_selected)
                }
                block={block}
                index={index}
              />
              <CancelButton block={block} index={index} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Islands;
