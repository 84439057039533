import React from "react";
import ButtonModeComponent from "../components/ButtonModeComponent";
import HotelRecapMode from "../components/HotelRecapMode";
import HotelPopMode from "../components/HotelPopMode";
import { useSelector } from "react-redux";
import { GetProductsType } from "../selectors/products";

const Hotels = ({ block, index }) => {
  const name = useSelector(GetProductsType(block));
  const { mode, disabled } = block;
  return (
    <>
      {mode === "button" && (
        <ButtonModeComponent name={name} disabled={disabled} block={block} />
      )}
      {mode === "recap" && (
        <HotelRecapMode block={block} index={index} name={name} />
      )}
      {mode === "popup" && <HotelPopMode block={block} name={name} />}
    </>
  );
};

export default Hotels;
