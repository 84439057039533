import React from "react";
import ExcursionCheckBox from "../components/ExcursionCheckbox";
import Select from "../components/Select";
import { RemoveTrailingZero } from "../helper";

const ExcursionRow = ({ excursion, dest, label, excursion_datas }) => {
  const { image, title, teaser, price, prestation_sub_code, validity_options } =
    excursion;
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = teaser;
  const textContent = tempDiv.textContent;

  return (
    <div className="flex desktop:items-center p-2.5 border rounded-md border-gray-200 tablet:flex-col">
      <div className="flex flex-1">
        <img
          src={image}
          alt=""
          className="w-133 object-cover rounded-md mr-5 h-90"
        />
        <div className="flex flex-col text-fs-14 flex-1 mr-5">
          <div className="flex flex-col">
            <span className="font-bold">{title}</span>
            {teaser !== null ? (
              <span className="w-value-522 mb-5">
                <p className="paragh-elipsis">{textContent}</p>
              </span>
            ) : (
              <></>
            )}
          </div>
          {validity_options.length > 1 ? (
            <div className="flex items-center mt-auto">
              <div className="font-bold mr-5">Jours de validité</div>
              <Select
                id={`excursion.${dest}.${prestation_sub_code}.validity_day`}
                options={validity_options}
                default_value={"all"}
                ClassNames="custom-select-arrow border border-gray-200 rounded-md w-180"
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="flex items-center tablet:mt-5 tablet:justify-center">
        <div className="flex flex-col">
          <div className="flex items-center">
            <span className="text-fs-21 leading-lh-32 font-bold">
              {price.price !== null ? RemoveTrailingZero(price.price) : "price"}{" "}
              €
            </span>
            /
            {price.type !== null ? (
              <span className="text-fs-12 pl-5px leading-lh-15">
                {price.type === "Par personne" ? "Pers." : "Chb"}
              </span>
            ) : (
              <></>
            )}
          </div>
          {price.board !== null ? (
            <span className="text-fs-13 w-value-140">{price.board}</span>
          ) : (
            <></>
          )}
        </div>
        <ExcursionCheckBox
          name={`excursion.${dest}.code`}
          id={prestation_sub_code}
          dest={dest}
          label={label}
          excursion_datas={excursion_datas}
        />
      </div>
    </div>
  );
};

export default ExcursionRow;
