import React from "react";
import { useController, useFormContext } from "react-hook-form";

const InputNumber = ({ id, default_value, min, max }) => {
  const {
    field: { onChange, onBlur, value, name },
  } = useController({
    name: id,
    defaultValue: default_value,
    rules: {
      valueAsNumber: true,
    },
  });
  const { clearErrors } = useFormContext();
  const handleDecrease = (e) => {
    clearErrors();
    e.preventDefault();
    onChange(value - 1);
  };

  const handleIncrease = (e) => {
    clearErrors();
    e.preventDefault();
    onChange(value + 1);
  };

  return (
    <div className="flex border border-gray-200 h-value-30 rounded-md">
      <button
        disabled={value === min}
        className={`group w-value-30 bg-grey-pagination rounded-l-md text-fs-26 flex items-center justify-center ${
          value <= min ? "disabled" : ""
        }`}
        onClick={handleDecrease}
      >
        <span className="group-[.disabled]:opacity-20">-</span>
      </button>
      <input
        onChange={onChange}
        onBlur={onBlur} // notify when input is touched/blur
        value={value} // input value
        name={name} // send down the input name
        type="number"
        className="w-value-30 text-center outline-0 text-fs-14"
      />
      <button
        disabled={value === max}
        className={`group w-value-30 bg-grey-pagination rounded-r-md text-fs-26 flex items-center justify-center ${
          value === max ? "disabled" : ""
        }`}
        onClick={handleIncrease}
      >
        <span className="group-[.disabled]:opacity-20">+</span>
      </button>
    </div>
  );
};

export default InputNumber;
