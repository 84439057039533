import React from "react";
import EditButton from "./EditButton";
import { getDatas } from "../selectors/userSelection";
import { useSelector } from "react-redux";
import calender from "../img/icons/calendar.png";
import formatDate from "../validation/formateDate";

const TransfertRecapMode = ({ data, block, index }) => {
  const datas = useSelector(getDatas);
  const { dest } = block;
  const destination = dest;
  const code =
    destination in datas["transfert"] &&
    "code" in datas["transfert"][destination]
      ? datas["transfert"][destination]["code"]
      : datas["rental"][destination]["subprovision_code"];
  const { location, transfert } = data;
  const transfert_data = { ...location, ...transfert };
  const tranfert_label = code in location ? "Location de voiture" : "Transfert";
  const transfert_content = transfert_data[code];
  const { image, title, subtitle, description } = transfert_content;

  return (
    <div className="bg-white p-5 rounded-md">
      <div className="flex justify-between items-center mb-5">
        <h3 className="gold-color text-fs-21 leading-lh-27 merriweather font-bold">
          Transfert / Location de voiture
        </h3>
        <EditButton block={block} index={index} />
      </div>
      <div className="bg-grey-pagination rounded-md main-color pt-value-24 px-5 pb-5">
        <div className="flex justify-between">
          <span className="text-fs-18 font-bold">{tranfert_label}</span>
          {tranfert_label === "Location de voiture" && (
            <div className="flex items-center">
              <div className="flex items-center">
                <div className="bg-white rounded-md flex items-center h-value-30 cursor-pointer px-5 ml-5">
                  <img className="w-15 h-15" src={calender} alt="calendar" />
                  <span className="text-fs-14 ml-2.5">
                    {formatDate(datas["rental"][destination]["date_start"])}
                  </span>
                </div>
                <div className="bg-white rounded-md flex items-center h-value-30 cursor-pointer px-5 ml-5">
                  <img className="w-15 h-15" src={calender} alt="calendar" />
                  <span className="text-fs-14 ml-2.5">
                    {formatDate(datas["rental"][destination]["date_end"])}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="bg-white mt-5 rounded-md flex items-center justify-between py-2 pl-4 pr-150">
          <div className="flex items-center">
            <span className="h-90 w-90 flex items-center justify-center">
              {image !== undefined && (
                <img
                  className="h-auto w-auto object-cover"
                  src={image["url"]}
                  alt={image["alt"]}
                />
              )}
            </span>
            <div className="ml-5 flex flex-col">
              <div className="mb-2.5">
                <span className="font-bold">{title} </span>
                <span>{subtitle}</span>
              </div>
              <div
                className="text-fs-13"
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransfertRecapMode;
