import { put } from "redux-saga/effects";
import { fetchWithTimeout } from "../helper";

function* fetchProducts(action) {
  let {
    products_desti,
    is_multi_island,
    staydates,
    adults,
    children,
    api_key,
  } = action.payload;
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const headers = new Headers();
  if (api_key.length > 0) headers.append("api-key", api_key);
  let request =
    `${API_ENDPOINT}/api/v1/products?code_desti=` +
    products_desti +
    "&date_in=" +
    staydates["startDate"] +
    "&date_out=" +
    staydates["endDate"] +
    "&pax_adult=" +
    adults +
    "&pax_child=" +
    children;

  try {
    const response = yield fetchWithTimeout(request, { headers });
    if (response.status === 200) {
      const json_non_formatted = yield response.json();
      const json = formatJson(json_non_formatted, is_multi_island);
      yield put({ type: "products/putProducts", json, desti: products_desti });
    } else {
      throw response;
    }
  } catch (e) {
    yield put({
      type: "products/errorApiProducts",
      message: e.message,
      desti: products_desti,
    });
  }
}

const formatJson = (json, is_multi_island) => {
  if (is_multi_island === "1") {
    const { products } = json;
    const groupedHotels = {};
    for (const nid in products) {
      const refVille = products[nid]["ref_ville"];
      if (!(refVille in groupedHotels)) {
        groupedHotels[refVille] = {};
        groupedHotels[refVille]["products"] = {};
      }
      groupedHotels[refVille]["products"][nid] = products[nid];
    }

    return groupedHotels;
  }

  return json;
};
export { fetchProducts };
