const getFlightState = (state) => state.flightReducer;
const getFlights = (state) => state.flightReducer.flight;
const getFlightsByType = (type) => (state) => state.flightReducer.flight[type];
const isSuccess = (type) => (state) => state.flightReducer.isSuccess[type];
const isLoading = (type) => (state) => state.flightReducer.isLoading[type];
const isError = (type) => (state) => state.flightReducer.isError[type];

const getFlightInfo = (state, flight_id) => {
  const flight = getFlights(state);
  if (flight_id in flight["external"]) {
    return flight["external"][flight_id];
  }
  return false;
};
const getTotalLag = (flightInfo) => {
  const lag_aller = parseInt(flightInfo["aller"]["lag"]);
  const lag_retour = parseInt(flightInfo["retour"]["lag"]);
  let total_lag = lag_aller + lag_retour;
  return { total_lag, lag_aller, lag_retour };
};

const getDetailDuration = (code) => (state) => {
  let detail_duration = {};
  const { info, ...datas } = getFlightInfo(state, code);
  Object.keys(info).forEach((key) => {
    let i = 0;
    detail_duration[`recap_vol_${key}`] = {};
    detail_duration[`recap_vol_${key}`]["segments"] = {};
    detail_duration[`recap_vol_${key}`]["from"] = datas[key]["from_terminal"];
    detail_duration[`recap_vol_${key}`]["to"] = datas[key]["to_terminal"];
    Object.keys(info[key]).forEach((segement) => {
      const {
        type,
        duration,
        from,
        from_time,
        to_time,
        departure_time,
        arrival_time,
        to,
        class_type,
        lag,
        company,
        baggage,
      } = info[key][segement];
      console.log("info[key][segement]", info[key][segement]);
      if (type === "segment") {
        detail_duration[`recap_vol_${key}`]["segments"][i] = {};
        detail_duration[`recap_vol_${key}`]["segments"][i]["duration"] =
          duration;
        detail_duration[`recap_vol_${key}`]["segments"][i]["ville_dep"] = from;
        detail_duration[`recap_vol_${key}`]["segments"][i]["ville_arr"] = to;
        detail_duration[`recap_vol_${key}`]["segments"][i]["cie"] =
          company["name"];
        detail_duration[`recap_vol_${key}`]["segments"][i]["datedep"] =
          departure_time;
        detail_duration[`recap_vol_${key}`]["segments"][i]["heuredep"] =
          from_time;
        detail_duration[`recap_vol_${key}`]["segments"][i]["datearr"] =
          arrival_time;
        detail_duration[`recap_vol_${key}`]["segments"][i]["class"] =
          class_type;
        detail_duration[`recap_vol_${key}`]["segments"][i]["baggage"] = baggage;
        detail_duration[`recap_vol_${key}`]["segments"][i]["heurearr"] =
          to_time;
        detail_duration[`recap_vol_${key}`]["segments"][i]["lag"] = lag;
        i++;
      }
    });
  });
  return detail_duration;
};

const getFiltersOptions = (flight_datas) => {
  const companies = [
    {
      value: "all",
      label: "Compagnie",
    },
  ].concat(
    Object.keys(flight_datas).map((key) => ({
      value: flight_datas[key]["aller"]["company"]["code"],
      label: flight_datas[key]["aller"]["company"]["name"],
    }))
  );

  const stopover_options = [
    {
      value: "all",
      label: "Escale(s)",
    },
    {
      value: "with",
      label: "Avec escale(s)",
    },
    {
      value: "without",
      label: "Sans escale(s))",
    },
  ];
  const duration_options = [
    {
      value: "all",
      label: "Temps de trajet",
    },
    {
      value: "asc",
      label: "Croissant",
    },
    {
      value: "desc",
      label: "Décroissant",
    },
  ];

  const bagage_options = [
    {
      value: "all",
      label: "Bagages",
    },
    {
      value: "avec",
      label: "Avec bagages",
    },
    {
      value: "sans",
      label: "Sans bagages",
    },
  ];

  const price_options = [
    {
      value: "all",
      label: "Prix du vol",
    },
    {
      value: "asc",
      label: "Prix croissant",
    },
    {
      value: "desc",
      label: "Prix décroissant",
    },
  ];

  return {
    company_options: getUniqueDatas(companies),
    stopover_options,
    duration_options,
    bagage_options,
    price_options,
  };
};

const convertToMinutes = (durationString) => {
  const [hours, minutes] = durationString
    .replace("h", "")
    .replace("min", "")
    .split(" ");
  const totalMinutes = parseInt(hours) * 60 + parseInt(minutes);
  return totalMinutes;
};

const filterFlightsData = (
  flight_datas,
  company,
  stop,
  duration,
  cabin,
  price,
  bagage
) => {
  let result = Object.keys(flight_datas);
  if (company !== undefined && company !== "all") {
    result = result.filter(
      (item) => flight_datas[item]["aller"].company.code === company
    );
  }

  if (stop !== undefined && stop !== "all") {
    if (stop === "with") {
      result = result.filter((item) => flight_datas[item]["aller"].stops !== 0);
    } else if (stop === "without") {
      result = result.filter((item) => flight_datas[item]["aller"].stops === 0);
    }
  }

  if (bagage !== undefined && bagage !== "all") {
    if (bagage === "avec") {
      result = result.filter(
        (item) => flight_datas[item]["aller"].baggage === true
      );
    } else if (bagage === "sans") {
      result = result.filter(
        (item) => flight_datas[item]["aller"].baggage === false
      );
    }
  }

  if (duration !== undefined && duration !== "all") {
    if (duration === "asc") {
      result = result.sort((a, b) => {
        const aValue = convertToMinutes(flight_datas[a]["aller"].duration);
        const bValue = convertToMinutes(flight_datas[b]["aller"].duration);
        return aValue - bValue;
      });
    } else if (duration === "desc") {
      result = result.sort((a, b) => {
        const aValue = convertToMinutes(flight_datas[a]["aller"].duration);
        const bValue = convertToMinutes(flight_datas[b]["aller"].duration);
        return bValue - aValue;
      });
    }
  }

  if (cabin !== undefined && cabin !== "all") {
    if (cabin === "Y") {
      result = result.filter(
        (item) => flight_datas[item]["aller"].class === "Economique"
      );
    } else if (cabin === "C") {
      result = result.filter(
        (item) => flight_datas[item]["aller"].class === "Affaire"
      );
    } else if (cabin === "F") {
      result = result.filter(
        (item) => flight_datas[item]["aller"].class === "Première"
      );
    }
  }

  if (price !== undefined && price !== "all") {
    if (price === "asc") {
      result = result.sort((a, b) => {
        const aValue = flight_datas[a]["aller"].price;
        const bValue = flight_datas[b]["aller"].price;
        return aValue - bValue;
      });
    } else if (price === "desc") {
      result = result.sort((a, b) => {
        const aValue = flight_datas[a]["aller"].price;
        const bValue = flight_datas[b]["aller"].price;
        return bValue - aValue;
      });
    }
  }

  return result;
};

const getUniqueDatas = (datas) => {
  const uniqueSet = new Set();
  const uniqueData = [];
  // Loop through the array and add unique objects to the new array
  datas.forEach((obj) => {
    const key = obj.value + obj.label;
    if (!uniqueSet.has(key)) {
      uniqueSet.add(key);
      uniqueData.push(obj);
    }
  });
  return uniqueData;
};

export {
  getFiltersOptions,
  getFlightState,
  getFlightInfo,
  getTotalLag,
  getFlights,
  getFlightsByType,
  isSuccess,
  isLoading,
  filterFlightsData,
  isError,
  getDetailDuration,
};
