import React from "react";
import { useDispatch } from "react-redux";
import { deleteMode } from "../actions";
import { useFormContext } from "react-hook-form";

const DeleteButton = ({ block, index }) => {
  const dispatch = useDispatch();
  const { getValues, clearErrors, unregister, setValue } = useFormContext();

  return (
    <button
      type="button"
      className="main-color text-fs-14 border-b-main-color flex self-center border-b-1-5 border-accent"
      onClick={(e) => {
        e.preventDefault();
        clearErrors();
        dispatch(
          deleteMode({ ...getValues(), block, index, unregister, setValue })
        );
      }}
    >
      Supprimer
    </button>
  );
};

export default DeleteButton;
