import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useController } from "react-hook-form";
import { useOnceCall } from "../hooks";
import { getAssurance } from "../actions";
import {
  isLoading,
  isSuccess,
  isError,
  getAssurances,
} from "../selectors/assurance";
import ButtonModeComponent from "../components/ButtonModeComponent";
import AssuranceRow from "../components/AssuranceRow";
import ValidateButton from "../components/ValidateButton";
import EditButton from "../components/EditButton";
import AssuranceRecapMode from "../components/AssuranceRecapMode";
import Loading from "../components/Loading";
import ErreurModal from "../components/ErreurModal";
import CancelButton from "../components/CancelButton";
import { getDatas } from "../selectors/userSelection";
import img from "../img/logo-presence.png";

const Assurance = ({ block, index }) => {
  const loading = useSelector(isLoading);
  const success = useSelector(isSuccess);
  const error = useSelector(isError);
  const { api_key } = useSelector(getDatas);
  const { mode, disabled } = block;
  const dispatch = useDispatch();

  useOnceCall(() => {
    dispatch(getAssurance({ api_key }));
  }, mode === "popup");

  let assurances = useSelector(getAssurances);

  const { field } = useController({
    name: "assurance",
    defaultValue: "none",
  });

  if (Object.keys(assurances).length > 0) {
    assurances = {
      ...assurances,
      none: {
        title: "Assurance refusée",
        prestation_code: "none",
      },
    };
  }

  return (
    <>
      {mode === "popup" && loading && <Loading />}
      {mode === "popup" && error && (
        <ErreurModal message={"Erreur de la recherche des assurances."} />
      )}
      {mode === "button" && (
        <ButtonModeComponent
          name="Assurance"
          disabled={disabled}
          block={block}
        />
      )}
      {mode === "recap" && (
        <div className="bg-white p-5 rounded-md">
          <div className="flex justify-between items-center mb-5">
            <h3 className="gold-color text-fs-21 leading-lh-27 merriweather font-bold">
              Assurance
            </h3>
            <EditButton block={block} index={index} />
          </div>
          <AssuranceRecapMode assurances={assurances} />
        </div>
      )}
      {mode === "popup" && success && (
        <div className="modal">
          <div className="modal-content max-w-1172">
            <div className="flex justify-between pb-1.5">
              <h3 className="gold-color text-fs-21 leading-lh-37 merriweather font-bold ">
                Assurance
              </h3>
            </div>

            <div className="flex justify-between items-end mb-value-30">
              <div className="flex flex-col text-fs-14 main-color">
                {api_key.length > 0 && (
                  <span>
                    Pour que vos clients voyagent en toute sérénité, nous vous
                    invitons à souscrire notre assurance.
                  </span>
                )}
                {api_key.length === 0 && (
                  <span>
                    Nous mettons tout en œuvre pour que votre voyage soit
                    idyllique, mais un imprévu peut survenir avant ou pendant
                    celui-ci. Personne n’est à l’abri de devoir annuler son
                    voyage à la dernière minute, de manquer son avion, ou de
                    perdre un bagage… <br />
                    La souscription à notre assurance vous permettra de voyager
                    en toute sérénité !
                  </span>
                )}

                <a
                  className="underline"
                  href={`${process.env.REACT_APP_API_ENDPOINT}/modules/custom/reservation_api/Pdf/MULFASC_MULTIRISQUES.pdf`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Voir le détail des garanties
                </a>
              </div>
              <img className="max-w-250 ml-value-60" src={img} alt=""></img>
            </div>

            <div className="flex flex-col main-color [&>div]:mb-2.5  [&>div:last-child]:mb-0">
              {Object.values(assurances).map((assurance, key) => {
                return (
                  <AssuranceRow
                    key={key}
                    title={assurance.title}
                    b2b_description={assurance.b2b_description}
                    price={assurance.price}
                    prestation_code={assurance.prestation_code}
                    onChange={field.onChange}
                    radioValue={field.value}
                  />
                );
              })}
            </div>
            <div className="flex flex-col mt-value-30">
              <ValidateButton
                classes={
                  "p-16-35 leading-lh-15 block mx-auto main-bg-color text-white br-26 text-fs-14 w-value-226 disabled:bg-disabled-button"
                }
                button={{
                  label: "Valider",
                  type: "SELECT_ASSURANCE",
                }}
                validate={false}
              />
              <CancelButton block={block} index={index} />
            </div>
          </div>
        </div>
      )}
      {mode === "popup" && loading && <Loading />}
    </>
  );
};

export default Assurance;
