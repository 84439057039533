import React from "react";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { format, addDays, parseISO } from "date-fns";
import { useSelector } from "react-redux";
import { getFirstDestination } from "../selectors/destination";
import DateInput from "../components/DateInput";
import ValidateButton from "../components/ValidateButton";
import LinkButton from "../components/LinkButton";
import warning from "../img/icons/warning.svg";
import DateRecapMode from "../components/DateRecapMode";

const DateComponent = ({ block, index }) => {
  const { node_path } = useSelector(getFirstDestination);
  const today = format(new Date(), "yyyy-MM-dd");
  const startDate = format(addDays(parseISO(today), 7), "yyyy-MM-dd");
  const endDate = format(addDays(parseISO(startDate), 10), "yyyy-MM-dd");
  const {
    formState: { errors },
  } = useFormContext();
  const { mode } = block;
  const validateStayDates = (value) => {
    const startDate = value["startDate"];
    const endDate = value["endDate"];

    if (!startDate || !endDate) {
      return "Ce champ est requis";
    }
    if ((new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24) < 2) {
      return "La durée de votre voyage doit être d'au moins 3 jours";
    }

    return true;
  };

  const validateStrings = [`staydates`];
  return (
    <>
      {mode === "recap" && <DateRecapMode index={index} block={block} />}
      {mode === "popup" && (
        <div className="modal">
          <div className="modal-content max-w-350 tablet:!ml-auto tablet:!mr-auto">
            <h3 className="gold-color text-fs-21 leading-lh-37 merriweather font-bold pb-5 border-b border-b-gray-200">
              Dates
            </h3>

            <span className="main-color text-fs-14 pt-5 block font-bold mb-4">
              Quelles sont vos dates de voyage ?
            </span>
            <div className="rounded-md bg-grey-pagination py-5 px-4 mb-2">
              <div className="flex flex-col cursor-pointer stayDatePicker">
                <DateInput
                  name="DATE DE SEJOUR"
                  id={`staydates`}
                  asSingle={false}
                  useRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  validateDate={validateStayDates}
                  placeholder={"JJ/MM/AAAA ~ JJ/MM/AAAA"}
                />
              </div>
            </div>
            <span className="main-color text-fs-12 leading-lh-18 text-justify ">
              Ces dates correspondent au début et à la fin de votre séjour sur
              place. Elles seront ajustées en fonction du temps de trajet à + ou
              - un jour.
            </span>
            {Object.keys(errors).length > 0 && (
              <div className="flex text-fs-14 px-5 py-3.5 bg-gold rounded-md items-center mb-value-10 bigdesktop:w-1132 bigdesktop:-ml-235 mt-2">
                <img className="self-start mr-5" src={warning} alt="" />
                <div>
                  <ErrorMessage
                    errors={errors}
                    name={`staydates`}
                    render={({ message }) => (
                      <span className="text-white block font-semibold">
                        {message}
                      </span>
                    )}
                  />
                </div>
              </div>
            )}
            <ValidateButton
              classes={
                "p-16-35 leading-lh-15 block mx-auto mt-value-20 main-bg-color text-white br-26 text-fs-14 w-value-226"
              }
              button={{
                label: "Valider",
                type: "SELECT_STAYDATES",
              }}
              validateStrings={validateStrings}
            />
            {node_path !== undefined && (
              <LinkButton
                button={{
                  label: "Annuler",
                }}
                classNames={
                  "main-color mt-value-20 text-fs-14 border-b-main-color flex self-center border-b-1-5 border-accent mx-auto"
                }
                link={`${process.env.REACT_APP_API_ENDPOINT}/${node_path}`}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DateComponent;
