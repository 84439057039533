import React from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import { callToAction } from "../actions";

const CallToActionButton = ({ button, block = {}, index }) => {
  const { getValues } = useFormContext();
  const dispatch = useDispatch();
  return (
    <button
      className="bg-white border-2 border-accent [&:hover]:bg-accent [&:hover]:text-white text-fs-14 rounded-34 h-value-40 main-color mb-5 font-semibold px-5"
      type="button"
      onClick={() =>
        dispatch(callToAction({ ...getValues(), button, block, index }))
      }
    >
      {button.label}
    </button>
  );
};

export default CallToActionButton;
