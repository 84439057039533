import React from "react";
import { useFormContext } from "react-hook-form";
import { appBrain } from "../actions";
import { useDispatch } from "react-redux";

const FlightControlledRadioButton = ({
  id,
  name,
  field,
  setValue,
  value,
  block,
  button,
}) => {
  const { getValues } = useFormContext();
  const dispatch = useDispatch();
  return (
    <div className="flex flex-col items-center justify-center h-value-83">
      <label className="rounded-input-radio !my-0">
        <input
          onChange={(e) => {
            // send data to react hook form
            field.onChange(e);
            setValue(e.target.id);
            dispatch(appBrain({ ...getValues(), button, block }));
          }}
          id={id}
          value={id}
          type="radio"
          name={name}
          checked={value === id}
        />
        <span className="checkmark"></span>
      </label>
      <span className="main-color text-fs-13 mt-value-5 font-semibold">
        Sélectionnez
      </span>
    </div>
  );
};

export default FlightControlledRadioButton;
