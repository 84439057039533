import React from "react";
import { Document, Page, pdfjs } from "../../node_modules/react-pdf/dist/esm";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { RemoveTrailingZero } from "../helper";
import { getFirsttKey } from "../helper";
import RoomTab from "../components/RoomTab";
import HotelGallery from "../components/HotelGallery";
import YouTubeVideo from "../components/YoutubeVideo";
import star from "../img/icons/stars-filled.svg";
import kitchen from "../img/icons/kitchen.svg";

const HotelTab = ({ product, code_hotel, block, openTab, setOpenTab }) => {
  const {
    label,
    teaser,
    category,
    category_complement,
    logos,
    pensions_data,
    images,
    video,
    plan,
    cancelation_terms,
    url,
    icons,
    price,
    price_type,
    price_details,
    formule,
    offers,
    form_only,
    rooms_edito,
    bundle,
    show_type,
    type,
    subtitle,
    resa_info,
  } = product;

  const tabs = {
    hotel: "L’hôtel",
    photos: "Photo(s)",
    videos: "Vidéo(s)",
    map: "Plan de l’hôtel",
    annulation: "Conditions d’annulation",
  };

  const hotelStars = Array.from({ length: category }, (_, index) => index);
  if (images === undefined) delete tabs.photos;
  if (video === undefined) delete tabs.videos;
  if (plan === undefined) delete tabs.map;
  if (cancelation_terms.length < 1) delete tabs.annulation;

  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  let img = {};
  if (typeof logos === "object" && Object.keys(logos).length > 0) {
    let key = getFirsttKey(logos);
    img = logos[key];
  }
  return (
    <div className="desktop:pl-value-30 flex-1">
      <div className="flex items-center justify-between mobile:items-start smalldesktop:flex-col mobiletablet:flex-col tabletdesktop:items-start flex-wrap">
        <div className="flex justify-between w-full">
          <div className="flex flex-wrap">
            <div className="mobile:mb-2.5 mr-2">
              <h2 className="pb-value-5 merriweather main-color font-bold tabletdesktop:text-fs-18 tabletdesktop:leading-lh-27">
                {label}
              </h2>
              <div className="flex">
                {show_type === "1" && (
                  <span className="text-fs-16 font-bold main-color mr-2.5">
                    {type}
                  </span>
                )}
                {show_type === "0" && subtitle !== null && (
                  <span className="text-fs-16 font-bold main-color mr-2.5">
                    {subtitle}
                  </span>
                )}
                <span className="flex stars stars-5 items-center">
                  {hotelStars.map((_, key) => {
                    return (
                      <img className="max-w-13" src={star} alt="" key={key} />
                    );
                  })}
                </span>
                <span className="text-fs-16 font-bold main-color">
                  {category_complement}
                </span>
              </div>
            </div>
            <div className="flex badges min-w-110 [&>img]:object-contain [&>img]:min-w-50">
              {Object.keys(img).length > 0 && (
                <img
                  className={"mh-50 mb-2 mr-2 last:mb-0"}
                  src={img?.url}
                  alt=""
                />
              )}
            </div>
          </div>
          {formule !== null && (
            <div className="flex mobile:w-full mobile:justify-between h-value-80 max-w-400">
              <div className="pr-value-40 flex flex-col justify-center whitespace-nowrap bg-white">
                <span className="font-semibold main-color text-fs-12">
                  À partir de
                </span>
                <span className="flex">
                  <span className="flex items-center">
                    <span className="font-bold main-color price mr-2.5 text-fs-21 leading-lh-21">
                      {RemoveTrailingZero(price)} €
                    </span>
                    <span className="flex flex-col">
                      {price_details !== null && (
                        <span className="text-fs-10 italic main-color">
                          ( {price_details} )
                        </span>
                      )}
                      <span className="main-color text-fs-12 leading-lh-15">
                        / {price_type === "Par chambre" ? "Chb." : "Pers."}
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div className="flex flex-col w-value-165 items-start justify-center main-color relative before:absolute before:h-value-60 before:top-2.5 before:w-px before:bg-grey-line before:-left-5">
                <span className="text-fs-14 block font-bold flex">
                  <img className="mr-2.5 self-center" src={kitchen} alt="" />
                  {formule}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="my-value-30 bg-light-grey rounded-md flex items-center text-fs-12 h-53 [&>button]:px-value-30 [&>button]:cursor-pointer [&>button]:border-r [&>button]:border-r-gray-200 font-bold [&>button:first-child]:rounded-l-md [&>button:last-child]:border-0">
        {Object.keys(tabs).map((key) => {
          return (
            <button
              className={`main-color flex items-center h-full [&:hover]:bg-accent [&.active]:bg-accent [&.active]:text-white [&:hover]:text-white ${
                openTab === key ? "active" : ""
              }`}
              key={key}
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(key);
              }}
            >
              {tabs[key]}
            </button>
          );
        })}
      </div>

      {openTab === "hotel" && (
        <RoomTab
          code_hotel={code_hotel}
          block={block}
          pensions_data={pensions_data}
          teaser={teaser}
          offers={offers}
          images={images}
          rooms_edito={rooms_edito}
          url={url}
          icons={icons}
          form_only={form_only}
          bundle={bundle}
          resa_info={resa_info}
        />
      )}

      {openTab === "photos" && images !== undefined && images.length > 0 ? (
        <HotelGallery ImagesArray={images} />
      ) : (
        <></>
      )}

      {openTab === "videos" && video !== undefined ? (
        <YouTubeVideo videoURL={video} />
      ) : (
        <></>
      )}

      {openTab === "map" && plan !== undefined && "img" in plan ? (
        <img src={plan.img} alt="plan d'hôtel" />
      ) : openTab === "map" && plan !== undefined && "doc" in plan ? (
        <Document file={plan.doc}>
          <Page
            className={"PDFPage "}
            pageNumber={1}
            renderTextLayer={false}
            renderInteractiveForms={false}
          />
        </Document>
      ) : (
        <></>
      )}

      {openTab === "annulation" && cancelation_terms !== undefined ? (
        <div
          className="main-color list-none [&>h3]:mb-4 [&>h3]:list-item [&>h3]:list-roman [&>h3]:list-inside [&>h3]:uppercase [&>h3]:font-bold [&>p]:mb-3"
          dangerouslySetInnerHTML={{ __html: cancelation_terms }}
        ></div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default HotelTab;
