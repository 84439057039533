import React from "react";
import { useController, useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import { updateDatas } from "../slice/userSelectionSlice";

const Checkbox = ({
  name,
  id,
  labelClassNames,
  defaultValue = false,
  validate,
}) => {
  const { clearErrors } = useFormContext();
  const controllerProps = {
    name: id,
    defaultValue: defaultValue,
    rules: {},
  };
  if (validate) {
    controllerProps["rules"]["validate"] = validate;
  }

  const { field } = useController(controllerProps);

  const dispatch = useDispatch();
  return (
    <label
      className={`${
        labelClassNames
          ? labelClassNames
          : "customcheckboxcontainer customcheckboxcontainerwhite main-color font-bold text-fs-14"
      }`}
      htmlFor={id}
    >
      {name}
      <input
        onChange={(e) => {
          clearErrors();
          field.onChange(e);
          dispatch(
            updateDatas({ button: { type: "UPDATE_MODE" }, mode: "edit" })
          );
          dispatch(
            updateDatas({
              button: { type: "UPDATE_FLIGHT_SELECTION" },
              no_flight: !field.value,
            })
          );
        }} // send value to hook form
        onBlur={field.onBlur} // notify when input is touched/blur
        checked={field.value} // input value
        name={field.name} // send down the input name
        ref={field.ref} // send input ref, so we can focus on input when error appear
        id={id}
        type="checkbox"
      />
      <span className="checkmark"></span>
    </label>
  );
};
export default Checkbox;
