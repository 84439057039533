import React from "react";
import ExcursionRow from "../components/ExcursionRow";
import { format } from "date-fns";

const ExcursionGrouped = ({ datas, dest, excursion_datas }) => {
  const { data, dates, label } = datas;
  return (
    <>
      <div className="flex justify-between pb-5">
        <h3 className="main-color text-fs-18 leading-lh-37 font-bold">
          <span className="font-bold merriweather">
            {label}
            {"  "}
          </span>
          {dates.map((date, key) => {
            return (
              <span key={key}>
                {key > 0 && <> & </>}
                {format(new Date(date["start_day"]), "dd/MM/yyyy")} -{" "}
                {format(new Date(date["end_day"]), "dd/MM/yyyy")}
              </span>
            );
          })}
        </h3>
      </div>
      <div className="flex flex-col main-color [&>div]:mb-2.5 [&>div:last-child]:mb-0">
        {Object.keys(data).map((key) => {
          return (
            <ExcursionRow
              excursion={data[key]}
              key={key}
              dest={dest}
              label={label}
              excursion_datas={excursion_datas}
            />
          );
        })}
      </div>
    </>
  );
};

export default ExcursionGrouped;
