import React from "react";
import { useSelector } from "react-redux";
import { getFirstDestination } from "../selectors/destination";
import { getDatas } from "../selectors/userSelection";
import calender from "../img/icons/calendar.png";
import formatDate from "../validation/formateDate";

import EditButton from "./EditButton";

const TravelersRecapMode = ({ block, index }) => {
  const getOptionsLabel = (options_array = [], value) => {
    for (let i = 0; i < options_array.length; i++) {
      if (options_array[i]["value"] === value) {
        return options_array[i]["label"];
      }
    }
  };
  const { adults, children, next_dest, profile, weddingdate, ...datas } =
    useSelector(getDatas);

  const { label_destination, combinaison_destinations } =
    useSelector(getFirstDestination);

  const multi_dest_options = Object.keys(combinaison_destinations || []).map(
    (key) => {
      return {
        label: `${label_destination} + ${combinaison_destinations[key]}`,
        value: key,
      };
    }
  );

  if (multi_dest_options.length !== 0) {
    multi_dest_options.unshift({ label: "Choisir une option", value: "none" });
  }

  const profile_options = [
    { label: "Sans Formule", value: "none" },
    { label: "Voyage de noces", value: "honeymoon" },
    {
      label: "Anniversaire de mariage",
      value: "wedding_anniversary",
    },
  ];

  const weddingDate =
    weddingdate !== undefined ? weddingdate[`startDate`] : null;

  return (
    <div className="bg-white px-5 py-value-30 rounded-md">
      <div className="flex justify-between mb-5">
        <h3 className="gold-color text-fs-21 leading-lh-27 merriweather font-bold">
          Voyageurs
        </h3>
        <EditButton block={block} index={index} />
      </div>
      <div className="bg-grey-pagination flex desktop:items-center justify-between rounded-md main-color tablet:flex-col">
        <div className="flex [&>div]:border-r [&>div]:border-r-gray-200 [&>div:last-child]:border-0">
          <div className="flex items-center px-5 py-4">
            <div className="flex flex-col mr-5">
              <p className="text-fs-14 leading-lh-18 font-semibold">
                Adulte{adults > 1 ? "s" : ""}
              </p>
              <span className="text-fs-12 leading-lh-18">18 ans et plus</span>
            </div>
            <div className="flex [&>span]:mr-2.5 [&>span:last-child]:mr-0">
              <span className="bg-white w-value-30 h-value-30 rounded-md flex justify-center items-center text-fs-14">
                {adults}
              </span>
            </div>
          </div>
          <div className="flex items-center px-5 py-4">
            <div className="flex flex-col mr-5">
              <p className="text-fs-14 leading-lh-18 font-semibold">
                Enfant{children > 1 ? "s" : ""}
              </p>
              <span className="text-fs-12 leading-lh-18">De 0 à 17 ans</span>
            </div>
            <div className="flex [&>span]:mr-2.5 [&>span:last-child]:mr-0">
              <span className="bg-white w-value-30 h-value-30 rounded-md flex justify-center items-center text-fs-14">
                {children}
              </span>
            </div>
          </div>
          {children > 0 ? (
            <div className="flex items-center px-5 py-4">
              <div className="flex flex-col mr-5">
                <p className="text-fs-14 leading-lh-18 font-semibold">
                  {children > 1 ? "Âge des enfants" : "Âge de l'enfant"}
                </p>
                <span className="text-fs-12 leading-lh-18">
                  Au moment du voyage
                </span>
              </div>
              <div className="flex [&>span]:mr-2.5 [&>span:last-child]:mr-0">
                {[...Array(parseInt(children))].map((_, i) => {
                  return (
                    <span
                      className="bg-white w-value-30 h-value-30 rounded-md flex justify-center items-center text-fs-14"
                      key={i}
                    >
                      {datas[`age_${i + 1}`]}
                    </span>
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>

        <div className="flex desktop:border-l desktop:border-l-gray-200 py-4 pl-5">
          {profile !== "none" && (
            <span className="bg-white h-value-30 px-4 rounded-md flex items-center justify-center mr-5">
              {getOptionsLabel(profile_options, profile)}
            </span>
          )}
          {profile === "wedding_anniversary" || profile === "honeymoon" ? (
            <div className="flex items-center">
              <span className="text-fs-14 font-bold">Date de mariage</span>
              <div className="bg-white rounded-md flex items-center h-value-30 cursor-pointer px-5 mr-5 ml-3">
                <img className="w-15 h-15" src={calender} alt="" />
                <span className="text-fs-14 ml-2.5">
                  {formatDate(weddingDate)}
                </span>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TravelersRecapMode;
