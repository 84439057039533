import React from "react";
import RadioButton from "./RadioButton";

const LocationRow = ({ transfer, name, code }) => {
  let { title, image, description, subtitle } = transfer;

  return (
    <div className="border border-gray-200 w-full rounded-md flex items-center justify-between py-2 px-4 mb-2.5">
      <div className="flex items-center">
        <span className="h-90 w-90 flex items-center justify-center">
          {image !== undefined && (
            <img
              className="h-auto w-auto object-cover"
              src={image["url"]}
              alt={image["alt"]}
            />
          )}
        </span>
        <div className="ml-5 flex flex-col">
          <div className="mb-2.5">
            <span className="font-bold">{title}</span>
            <span className="block">{subtitle}</span>
          </div>
          <div dangerouslySetInnerHTML={{ __html: description }}></div>
        </div>
      </div>
      <RadioButton id={`${code}`} value={`${code}`} name={name} />
    </div>
  );
};

export default LocationRow;
