import React from "react";
import { Tooltip } from "react-tooltip";

const TooltipHotel = ({
  src,
  data,
  id,
  content = "",
  active,
  image_classnames,
}) => {
  return (
    <div className="tooltip-container">
      <a
        data-tooltip-id={id}
        data-tooltip-content={content}
        data-tooltip-place="bottom"
        className="flex items-center mr-5 custom-tooltip"
        href="#link"
      >
        <img
          className={`${
            image_classnames === undefined ? "h-5 mr-value-5" : image_classnames
          }`}
          src={src}
          alt=""
        />
        <span>{data}</span>
      </a>
      {active === true && (
        <Tooltip
          id={id}
          className="tooltip"
          classNameArrow="example-arrow"
          noArrow={true}
        />
      )}
    </div>
  );
};

export default TooltipHotel;
