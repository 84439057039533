import React from "react";
import ButtonModeComponent from "../components/ButtonModeComponent";
import EditButton from "../components/EditButton";
import DeleteButton from "../components/DeleteButton";
import ExcursionRecapModeGrouped from "../components/ExcursionRecapModeGrouped";
import ExcursionPopUpMode from "../components/ExcursionPopUpMode";
import { useSelector } from "react-redux";
import { getDatas } from "../selectors/userSelection";

const Excursion = ({ block, index }) => {
  const { mode, disabled, datas } = block;
  const datas_user = useSelector(getDatas);
  const excursion_datas = datas_user.excursions;
  return (
    <>
      {mode === "button" && (
        <ButtonModeComponent
          name={"Excursion"}
          disabled={disabled}
          block={block}
        />
      )}
      {mode === "recap" && (
        <div className="bg-white p-5 rounded-md">
          <div className="flex justify-between items-center mb-5">
            <h3 className="gold-color text-fs-21 leading-lh-27 merriweather font-bold">
              Excursions
            </h3>
            <div className="flex">
              <EditButton
                block={block}
                index={index}
                classNames={
                  "main-color text-fs-14 border-b-main-color flex self-center border-b-1-5 border-accent mr-4"
                }
              />
              <DeleteButton block={block} index={index} />
            </div>
          </div>
          <div className="p-5 rounded-md bg-grey-pagination [&>div]:mb-2.5 [&>div:last-child]:mb-0 mb-2">
            {Object.keys(excursion_datas).map((dest) => {
              return (
                <ExcursionRecapModeGrouped
                  key={dest}
                  user_datas={excursion_datas[dest]}
                  datas_content={datas[dest]}
                />
              );
            })}
          </div>
        </div>
      )}
      {mode === "popup" && (
        <ExcursionPopUpMode datas={datas} block={block} index={index} />
      )}
    </>
  );
};

export default Excursion;
