import { createSlice } from "@reduxjs/toolkit";

const flightSlice = createSlice({
  name: "flight",
  initialState: {
    flight: {},
    message: {},
    isLoading: {},
    isSuccess: {},
    isError: {},
  },
  reducers: {
    getFlights: (state, action) => {
      const type = action.payload.flight_type;
      state.isLoading[type] = true;
      state.isSuccess[type] = false;
      state.isError[type] = false;
    },
    putFlights: (state, action) => {
      const type = action.flight_type;
      state.flight[type] = action.json;
      state.isLoading[type] = false;
      state.isSuccess[type] = true;
    },
    errorApiFlights: (state, action) => {
      const type = action.flight_type;
      state.message[type] = action.message;
      state.isLoading[type] = false;
      state.isError[type] = true;
    },
    resetFlights: (state) => {
      state.flight = {};
      state.message = {};
      state.isLoading = {};
      state.isSuccess = {};
      state.isError = {};
    },
  },
});

export const flightReducer = flightSlice.reducer;

export const { getFlights, resetFlights } = flightSlice.actions;
